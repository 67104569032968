import React from 'react'
import AdminLayout from '../../Layout/AdminLayout'
import ScheduleComp from '../../Components/modules/Schedule'

export default function Schedule() {
  return (
    <AdminLayout active={5}>
      <ScheduleComp/>
    </AdminLayout>
  )
}
