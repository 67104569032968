import React from "react";
import { Offcanvas } from "react-bootstrap";
import ButtonComp from "../../Components/Ui/Button";
import { useUpdateCustomerMutation } from "../../store/Customer/CustomerApi";
import { toast } from "react-toastify";

const EditCustomer = ({
  openSide,
  setCurrentCustomer,
  currentCustomer,
  setOpenSide,
  id,
}) => {
  const [updateCustomer, { isLoading, isSuccess }] =
    useUpdateCustomerMutation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentCustomer((prevCustomer) => ({
      ...prevCustomer,
      [name]: value,
    }));
  };

  const handleUpdateCustomer = async () => {
    //   console.log(customerValues, "Database object");
    const customerValues = {
      contactPersonName: currentCustomer?.contactPersonName,
      businessName: currentCustomer?.businessName,
      email: currentCustomer?.email,
      businessPhoneNumsber: currentCustomer?.businessPhoneNumber,
      address: currentCustomer?.address,
      licenceNumber: currentCustomer?.licenceNumber,
      premiseLicence: currentCustomer?.premiseLicence,
    };

    try {
      const res = await updateCustomer({ form: customerValues, id });
      if (res?.data?.success) {
        setCurrentCustomer(res?.data?.data);
        toast.success("Updated successfully");
      } else {
        toast.error("Error While updating customer");
      }
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    }

    // console.log(res.data.data);
  };

  console.log(currentCustomer);

  return (
    <Offcanvas
      show={openSide}
      onHide={() => setOpenSide(false)}
      placement="end"
    >
      <Offcanvas.Header className="mx-2">
        <div className="w-full">
          <div
            className="mt-[10px] flex justify-between items-end w-full cursor-pointer"
            onClick={() => setOpenSide(false)}
          >
            <img src="/icon/close.svg" alt="Close" />
          </div>
          <div className="mt-[30px] flex justify-between items-center w-full">
            <h5 className="mb-0 font-semibold text-right w-full block">
              Customer Profile
            </h5>
          </div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="item_container flex items-center justify-center flex-col gap-[1rem] w-full">
          <div className="form-group w-full">
            <label
              htmlFor="contactPersonName"
              className="mb-[.5rem] text-[.9rem] font-[500]"
            >
              Contact Person
            </label>
            <input
              type="text"
              className="form-control"
              name="contactPersonName"
              value={currentCustomer?.contactPersonName || ""}
              onChange={handleChange}
            />
          </div>
          <div className="form-group w-full">
            <label
              htmlFor="businessName"
              className="mb-[.5rem] text-[.9rem] font-[500]"
            >
              Business Name
            </label>
            <input
              type="text"
              className="form-control"
              name="businessName"
              value={currentCustomer?.businessName || ""}
              onChange={handleChange}
            />
          </div>

          <div className="form-group w-full">
            <label
              htmlFor="email"
              className="mb-[.5rem] text-[.9rem] font-[500]"
            >
              Email Address
            </label>
            <input
              type="email"
              className="form-control"
              name="email"
              value={currentCustomer?.email || ""}
              onChange={handleChange}
            />
          </div>
          <div className="form-group w-full">
            <label
              htmlFor="businessPhoneNumber"
              className="mb-[.5rem] text-[.9rem] font-[500]"
            >
              Phone Number
            </label>
            <input
              type="text"
              className="form-control"
              name="businessPhoneNumber"
              value={currentCustomer?.businessPhoneNumber || ""}
              onChange={handleChange}
            />
          </div>
          <div className="form-group w-full">
            <label
              htmlFor="address"
              className="mb-[.5rem] text-[.9rem] font-[500]"
            >
              Address
            </label>
            <input
              type="text"
              className="form-control"
              name="address"
              value={currentCustomer?.address || ""}
              onChange={handleChange}
            />
          </div>
          <div className="button-container mt-[2rem] w-full">
            <ButtonComp
              loader={isLoading}
              text={"Edit Customer"}
              onClick={handleUpdateCustomer}
              btnClassName={"text-white"}
              wrapper={
                "bg-[#4F008A] w-full rounded-full h-[3rem] flex items-center justify-center"
              }
            />
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default EditCustomer;
