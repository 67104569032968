import React, { useState } from 'react'
import ListViewInCanvas from '../../../Common/ListViewInCanvas'
import { ImageComponent } from '../../../Common/FallbackImage'
import ButtonComp from '../../../Ui/Button'
import { formatMoney } from '../../../../utils/formatMoney'
import moment from 'moment'
import { statusStyleII } from '../../Customer/Data'
import { TableComp } from '../../../Common/TableComp'
import { OrderDetails } from '../Data'

export default function ViewProductDetails({Data,handleSubmit,handleUpdateOrder}) {
    const [pageName,setPageName] =useState('Order Overview')
  return (
    <div className='px-3'>
    <div className='inline-block mb-4'>
        <div className='flex gap-3 border-b-[2px] border-b-[rgba(79, 0, 138, 0.1)]  '>
        <div onClick={()=>setPageName('Order Overview')} className={`cursor-pointer pb-2 font-medium ${pageName==="Order Overview" ?'text-[#282728] border-b-[#3DA84A] border-b-[2px] ':'text-[#757575]'}`}>Order Overview</div>
        <div onClick={()=>setPageName('Product details')} className={`cursor-pointer pb-2 font-medium ${pageName==="Product details" ?'text-[#282728] border-b-[#3DA84A] border-b-[2px] ':'text-[#757575]'}`}>Product details</div>
    
    </div>
    </div>
    {pageName==="Order Overview" && <OrderOverviewComp Data={Data}
    handleSubmit={handleSubmit}
    handleUpdateOrder={handleUpdateOrder}
    />}
    {pageName==="Product details" && <ProductDetailsComp Data={Data}
    handleSubmit={handleSubmit}
    handleUpdateOrder={handleUpdateOrder}
    />}
    
    </div>
  )
}

const OrderOverviewComp = ({Data,handleSubmit,handleUpdateOrder}) =>{
    return  <div>
    <div className='border-[rgba(79, 0, 138, 0.1)] border-[1px] py-[50px] pl-[20px] xl:pl-[30px] pr-[20px] xl:pr[0px] flex-1 mb-[30px]'>
   <div className='row gy-4'>
   <div className='col-lg-6 '>
        <ListViewInCanvas
        title={'Field Rep'}
        ItemName={`${Data?.user?.firstName} ${Data?.user?.lastName}`}
       
        />
    </div>
    <div className='col-lg-6 '>
        <ListViewInCanvas
        title={' Company'}
        ItemName={Data?.customer?.businessName}
        />
    </div>
    <div className='col-lg-6 '>
        <ListViewInCanvas
        title={' Total Cost'}
        ItemName={<div>{formatMoney(Data?.total,'','')}</div>}
        />
    </div>
    <div className='col-lg-6 '>
        <ListViewInCanvas
        title={' Total Paid'}
        ItemName={<div>{formatMoney(Data?.total-Data?.balanceAmount,'','')}</div>}
        />
    </div>
    <div className='col-lg-6 '>
        <ListViewInCanvas
        title={'Due Date'}
        ItemName={<div>{Data?.payLaterDate}</div>}
        // ItemName={<div>{moment(Data?.createdAt).format("MMM Do YY")}</div>}
        />
    </div>
    {/* <div className='col-lg-6 '>
        <ListViewInCanvas
        title={'Due Date'}
        ItemName={<div>{Data?.customer?.address}</div>}
        // ItemName={<div>{moment(Data?.createdAt).format("MMM Do YY")}</div>}
        />
    </div> */}
   
    <div className='col-lg-6 '>
        <ListViewInCanvas
        title={'Payment Status'}
        ItemName={Data?.status}
        />
    </div>
    <div className='col-lg-6 '>
        <ListViewInCanvas
        title={'Payment Method'}
        ItemName={'-'}
        />
    </div>
    <div className='col-lg-6 '>
        <ListViewInCanvas
        title={'Status'}
        ItemName={<div className={statusStyleII(Data?.status)}>{Data?.status}</div>}
        />
    </div>
    <div className='col-lg-6 '>
        <ListViewInCanvas
        title={'Description/Report'}
        ItemName={'-'}
        />
    </div>
    
    
   </div>
</div>
<div className='flex justify-center gap-[20px]'>
    <ButtonComp
    onClick={()=>handleSubmit()}
    text={'Decline Order'}
    btnClassName={'border-[#D63434] border-[1px] text-[#D63434] rounded-full px-[25px] py-2 text-[14px] font-medium'}
    />
    <ButtonComp
    onClick={handleUpdateOrder}
    text={'Accept Order'}
    btnClassName={'border-[#4F008A] bg-[#4F008A] border-[1px]  rounded-full px-[35px] text-white py-2 text-[14px] font-medium'}
    />
</div>
</div>
}
const ProductDetailsComp = ({Data,handleSubmit,handleUpdateOrder}) =>{
    console.log(Data?.orderItems,'Data?.orderItems')
    return  <div>
        <div className='mb-4 row gap-3 '>
            <div className='col-lg-4 p-3 bg-[#F3FFF5]'>
                <div className='text-[#444444] text-[14px] mb-2 font-medium'>Total Amount</div>
                <div className='text-[#282728] text-[18px] font-bold'>{formatMoney(Data?.total,false)}</div>
            </div>
            {/*  */}
            <div className='col-lg-3 p-3 bg-[#FCFCE9]'>
                <div className='text-[#444444] text-[14px] mb-2 font-medium'>Total Product</div>
                <div className='text-[#282728] text-[18px] font-bold'>{Data?.orderItems?.length}</div>
            </div>
            {/*  */}
            <div className='col-lg-3 p-3 bg-[#EDF4FC]'>
                <div className='text-[#444444] text-[14px] mb-2 font-medium'>Total Quantity</div>
                <div className='text-[#282728] text-[18px] font-bold'>{Data?.orderItems?.reduce((acc, curr) => acc + curr.quantity, 0)}</div>
            </div>
        </div>
    <div className='border-[rgba(79, 0, 138, 0.1)] border-[1px] py-[50px] pl-[20px] xl:pl-[30px] pr-[20px] xl:pr[0px] flex-1 mb-[30px]'>
   <div className='row gy-4'>
   <TableComp
//    OrderDetails={OrderDetails}
  columnsComp={OrderDetails(Data?.orderItems)}
Data={Data?.orderItems}
   />
    
    
   </div>
</div>
<div className='flex justify-center gap-[20px]'>
    <ButtonComp
    onClick={()=>handleSubmit()}
    text={'Decline Order'}
    btnClassName={'border-[#D63434] border-[1px] text-[#D63434] rounded-full px-[25px] py-2 text-[14px] font-medium'}
    />
    <ButtonComp
    onClick={handleUpdateOrder}
    text={'Accept Order'}
    btnClassName={'border-[#4F008A] bg-[#4F008A] border-[1px]  rounded-full px-[35px] text-white py-2 text-[14px] font-medium'}
    />
</div>
</div>
}