import React, { useState, useEffect } from "react";
import { useGetDebtorsReportQuery } from "../../../../store/Order/OrderApi";
import { TableComp } from "../../../Common/TableComp";
import { OrderColumnsII } from "../Data";

const DebtorProfile = ({ repId, getProfile, debtorLoading, debtorSuccess }) => {
  const [debtors, setDebtors] = useState([]);
  useEffect(() => {
    if (debtorSuccess) {
      setDebtors(getProfile);
    }
  }, [getProfile]);

  debtors && console.log(debtors);

  return (
    <div>
      {debtors?.length === 0 ? (
        <div className="item_container flex flex-col gap-[.7rem] mb-[8rem] items-center justify-center h-full w-full text-center">
          <img
            src="/svgs/noSchedule.svg"
            alt=""
            className="w-[5rem] h-[5rem]"
          />
          <span className="block">No Data</span>
        </div>
      ) : (
        <TableComp
          Data={[...debtors]?.reverse()}
          columnsComp={OrderColumnsII([...debtors]?.reverse())}
          pagination
          sortId={0}
        />
      )}
    </div>
  );
};

export default DebtorProfile;
