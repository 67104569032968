import React, { useEffect, useState } from "react";
import { TableComp } from "../../Common/TableComp";
import { useNavigate, useParams } from "react-router-dom";
import OffCanvasComp from "../../Ui/OffCanvas";
import TopHeader from "../../Common/TopHeader";
import { ScheduleColumns } from "./Data";
import SubHeader from "../../Common/SubHeader";
import { ImageComponent } from "../../Common/FallbackImage";
import ButtonComp from "../../Ui/Button";
import ModalComp from "../../Ui/ModalComp";
import TextareaComp from "../../Ui/TextArea";
import { FiEdit } from "react-icons/fi";
import CreateSchedule from "./submodules/CreateSchedule";
import ViewProductDetails from "./submodules/ViewProductDetails";
// import InputComp from "../../Ui/InputComp";
import { useGetScheduleQuery } from "../../../store/Customer/CustomerApi";
import SelectCompI from "../../Ui/SelectCompI";
import { currentDate, firstDateOfTheMonth } from "../../../utils/helper";
export default function VisitTargetComp() {
  const navigate = useNavigate();
  let { id,pagename } = useParams();
  const [showCavans, setShowCavans] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [searchField, setSearchField] = useState("");

  const [startDate, setStartDate] = useState(firstDateOfTheMonth);
  const [endDate, setEndDate] = useState(currentDate);
  const {
    data: getScheduleData,
    isLoading: getScheduleLoader,
    isSuccess: getScheduleisSuccess,
    isFetching:getScheduleReFreshing
    // refetch
  } = useGetScheduleQuery(
    {
      startDate, endDate
    },
    {
      // skip:!startDate||!endDate,
       refetchOnMountOrArgChange: false,

      // skipToken: startDate||startDate
    },
  );

  //  console.log(startDate.toLocaleDateString('en-GB'), "getScheduleData");
  const tableDropDown = [
    {
      name: (
        <div className="text-[#656468] text-[13px] flex gap-2 items-center">
          <FiEdit color="#4F008A" /> View Schedule
        </div>
      ),
      action: (row) => {
        navigate(`/schedule/${row?.id}/Schedule`);
        setShowCavans('Schedule')
      },
    },
    {
      name: (
        <div className="text-[#656468] text-[13px] flex gap-2 items-center">
          <img alt="" src="/icon/refresh.svg" />
          Track Field Rep
        </div>
      ),
      action: (row) => {
        navigate(`/schedule/showlocation/${row?.userId}/${row?.id}`);
      },
    },
    {
      name: (
        <div className="text-[#D63434] text-[13px] flex gap-2 items-center">
          <img alt="" src="/icon/red-trash.svg" />
          Delete Schedule
        </div>
      ),
      action: (row) => {
        // console.log(row, "row");
        setShowModal(true)
        // setShowCavans(true);
      },
    },
  ];

  const OffCanvasPage = [
    {
      name: "Schedule",
      component:  <ViewProductDetails data={getScheduleData?.data.find((item) => Number(item?.id) === Number(id))}
    />,
    },
    {
      name: "Create New Schedule",
      component: <CreateSchedule />,
    },
  ];

  useEffect(() => {
    if (id) {
      setShowCavans(pagename);

    }
  }, [id,pagename]);
  return (
    <div>
      <TopHeader
        addBtnAction={() => setShowCavans("Create New Schedule")}
        title={`Visit Target (${getScheduleData?.data?.length||0})`}
        navigate={navigate}
        btnText={"Create New Visit"}
      />
      <SubHeader
        onChange={setSearchField}
        inputPlaceholder={"Search For Area or Field Staff"}
        statusAllBtn
        CategoryBtn
        filterTitle='Visit Filter'
        filterLoader={getScheduleReFreshing||getScheduleLoader}
        filterStartDate={startDate}
        filterEndDate={endDate}
        filterStartSetDate={setStartDate}
        filterEndSetDate={setEndDate}
        // filterBtn={()=>refetch()}
      />
      <div className="">
        <TableComp
          Data={getScheduleData?.data
            ?.filter(
              (item) =>
                item?.user?.firstName
                  .toLowerCase()
                  .match(searchField?.toLowerCase()) ||
                item?.customer?.businessName
                  .toLowerCase()
                  .match(searchField?.toLowerCase())
              // ||
              //    item?.status
              //   .toLowerCase()
              //   .match(searchField?.toLowerCase())
            )
            .reverse()}
          columnsComp={ScheduleColumns(
            tableDropDown,
            getScheduleData?.data?.length > 0 &&
              [...getScheduleData?.data]?.reverse()
          )}
          pagination
        />
      </div>
      <OffCanvasComp
        title={showCavans}
        show={showCavans}
        bodyComponent={
          OffCanvasPage?.find((item) => item?.name === showCavans)?.component
        }
        handleClose={() => setShowCavans(null)}
      />
      <ModalComp
        show={showModal}
        bodyComponents={<DeclineOrderModal />}
        handleClose={() => {
          // navigate("/schedule");
          setShowModal(null);
        }}
      />
    </div>
  );
}

const DeclineOrderModal = () => {
  return (
    <>
      <div className="flex justify-center mb-[20px]">
        <ImageComponent src="/icon/status.svg" />
      </div>
      <div className=" mb-[20px] lg:mb-[32px]">
        <h5 className="font-semibold text-[18px] lg:text-[20px] mb-[20px] mx-[30px] lg:mx-[72px] leading-[32px]  text-center">
          Update Schedule Status
        </h5>
        <div className="flex justify-center">
          <form className="col-9">
            <SelectCompI
              label={"Status"}
              labelClassName={"text-[#4F008A]"}
              placeholder={"Choose Current Status"}
              wrapperClassName={"mb-[20px]"}
              options={[{name:'Pending',label:'Pending'}]}
            />
            <TextareaComp
              label={"Description"}
              labelClassName={"text-[#4F008A]  text-left"}
              placeholder={"Add a Comment..."}
              wrapperClassName={"mb-[10px]"}
              rows={3}
            />
          </form>
        </div>
      </div>
      <div className="row  mb-[64px] gap-[15px] justify-center">
        <div className="col-5">
          <ButtonComp
            wrapper={"w-full"}
            text={"Cancel"}
            btnClassName={
              "text-[14px] w-full lg:text-[16px] w-full  py-[5px] lg:py-[13px] border-[0.64px] rounded-full border-[#D63434] text-[#D63434]"
            }
          />
        </div>
        <div className="col-5">
          <ButtonComp
            wrapper={"w-full"}
            btnClassName={
              "text-[14px] w-full lg:text-[16px] w-full py-[5px] lg:py-[13px]  border-[0.64px] rounded-full border-[#3DA84A] text-[#fff] bg-[#3DA84A]"
            }
            text={"Save Changes"}
          />
        </div>
      </div>
    </>
  );
};
