import React from "react";
import { CSVLink } from "react-csv";

const flattenObject = (obj, parent = "", res = {}) => {
  for (let key in obj) {
    let propName = parent ? parent + "." + key : key;
    if (typeof obj[key] == "object" && obj[key] !== null) {
      flattenObject(obj[key], propName, res);
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
};

const CsvDownloadBtn = ({ data, filename = "report.csv" }) => {
  const flattenedData = data ? data.map((item) => flattenObject(item)) : [];
  const headers = flattenedData.length
    ? Object.keys(flattenedData[0]).map((key) => ({ label: key, key }))
    : [];

  return (
    <CSVLink data={flattenedData} headers={headers} filename={filename}>
      <button className="border-solid no-underline border-[1px] p-[.7rem] flex items-center justify-center gap-[.5rem] border-[#8354CF] text-[.8rem] text-[#8354CF] rounded-[5px]">
        <img src="/svgs/download.svg" alt="" />
        Download Report
      </button>
    </CSVLink>
  );
};

export default CsvDownloadBtn;
