import { createSlice ,current} from "@reduxjs/toolkit";
import { DeleteAuthTokenMaster, getAuthToken, setAuthToken, storage } from "../../utils/helper";
import { userLogin } from "./authAction";

const initialState = {
  loading: storage["localStorage"].get('user_details_profile')?.user?true:false,
  isLoggedIn: false,
  userInfo:storage["localStorage"].get('user_details_profile')?.user, // for user object
  // userToken: getAuthToken(), // for storing the JWT
  error: null,
  success: false, 
  
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setPharamData: (state, {payload}) => {
      state.pharamData = payload;
      state.companyId = payload.company_id
      storage["localStorage"].set("pharamsve_details_profile", payload)
      state.loading=true

    },
    setUserData: (state, {payload}) => {
      state.userData = payload;
      state.token= payload.token
      storage["localStorage"].set("user_details_profile", payload)
      state.userInfo=storage["localStorage"].get('user_details_profile')?.user
      state.loading=true
    },
    logout: (state)=>{
      state.pharamData = null;
      state.userData = null;
      storage["localStorage"].remove("pharamsve_details_profile")
      storage["localStorage"].remove("user_details_profile")
      state.loading=false
    }
  },
});

export const { reducer, actions } = authSlice; 
export const { logout, setPharamData, setUserData } = actions;
export const authState = reducer 
export const selectCurrentPharamaserveData = (state)=> state.auth.pharamData
export const selectCurrentUserData = (state)=> state.auth.userInfo
export const Check = (state)=> state.auth
export const selectCurrentPharamaserveId = (state)=> state.auth.companyId
export const isAuth = (state)=> state.auth.loading

