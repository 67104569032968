import React from 'react'
import AdminLayout from '../../Layout/AdminLayout'
import Admin_Console from '../../Components/modules/Admin_Console/Admin_Console'

export default function Console() {
  return (
    <AdminLayout active={6}>
        <Admin_Console/>
    </AdminLayout>
  )
}
