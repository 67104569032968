import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../api";

export const OrderApi = createApi({
  reducerPath: "OrderApi",
  baseQuery: baseQuery,

  endpoints: (builder) => ({
    getAllOrder: builder.query({
      query: (month) => ({
        url: `/supplier/order/all`,
        method: "GET",
      }),
    }),
    getDebtorsReport: builder.query({
      query: (userId) => ({
        url: `/report/debtor/${userId}`,
        method: "GET",
      }),
    }),
    getStockReport: builder.query({
      query: (userId) => ({
        url: `/supplier/order/medrep/stock/order-items/${userId}`,
        method: "GET",
      }),
    }),
    acceptOrder: builder.mutation({
      query: (payload) => ({
        url: `supplier/order/${payload?.orderId}/approve`,
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => response.data,
    }),
    declineOrder: builder.mutation({
      query: (payload) => ({
        url: `supplier/order/${payload?.orderId}/decline`,
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetAllOrderQuery,
  useDeclineOrderMutation,
  useAcceptOrderMutation,
  useGetDebtorsReportQuery,
  useGetStockReportQuery,
} = OrderApi;
