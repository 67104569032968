import React from "react";
import InputComp from "../../../Ui/InputComp";
import SelectCompI from "../../../Ui/SelectCompI";
import ButtonComp from "../../../Ui/Button";

export default function CreateSchedule() {
  return (
    <div>
      <div>
        <div className="border-[rgba(79, 0, 138, 0.1)] border-[1px] py-[50px] pl-[20px] xl:pl-[30px] pr-[20px] xl:pr[0px] flex-1 mb-[30px]">
            <SelectCompI
            label={'Field Rep'}
            labelClassName={'text-[#4F008A] mb-[8px]'}
            placeholder={'Choose Field Rep'}
            wrapperClassName={'mb-4'}
            />
            <SelectCompI
            label={'Company/Customer Name'}
            labelClassName={'text-[#4F008A] mb-[8px]'}
            placeholder={'Choose Company/Customer'}
            wrapperClassName={'mb-4'}
            />
            <InputComp
            label={'Reason For Visitation'}
            labelClassName={'text-[#4F008A] mb-[8px]'}
            placeholder={'Choose Company/Customer'}
            wrapperClassName={'mb-4'}
            />
            <div className="row">
            <InputComp
            label={'Visitation Date'}
            type='date'
            labelClassName={'text-[#4F008A] mb-[8px]'}
            placeholder={''}
            wrapperClassName={'mb-4 col-lg-6'}
            />
            <InputComp
            label={'Visitation Time'}
            type='time'
            labelClassName={'text-[#4F008A] mb-[8px]'}
            placeholder={''}
            wrapperClassName={'mb-4 col-lg-6'}
            />
            </div>
            <InputComp
            label={'Address'}
            // type='date'
            labelClassName={'text-[#4F008A] mb-[8px]'}
            placeholder={'Enter Full Address Of the Visitation'}
            wrapperClassName={'mb-4 col-lg-12'}
            />
        </div>
        <div className='flex  gap-[20px] px-[50px]'>
        <ButtonComp
        wrapper={'w-full'}
        text={'Discard'}
        btnClassName={'border-[#D63434] border-[1px] text-[#282728] rounded-full w-full py-2 text-[14px] font-medium'}
        />
        <ButtonComp
                wrapper={'w-full'}

        text={'Create Schedule'}
        btnClassName={'border-[#4F008A] bg-[#4F008A] border-[1px]  rounded-full w-full text-white py-2 text-[14px] font-medium'}
        />
    </div>
      </div>
    </div>
  );
}
