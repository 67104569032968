import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import ButtonComp from "../Ui/Button";
import { useTranslation } from "react-i18next";

export default function DateFilter({
  filterTitle = "DashBoard Metric",
  filterLoader = false,
  filterEndDate,
  filterStartDate,
  filterEndSetDate,
  filterStartSetDate,
  filterBtn,
  dateFormat = "dd/MM/yyyy",
}) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
  }, [i18n]);
  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle
          variant=""
          className="bg-primaryI px-4 text-white"
          id="dropdown-basic"
        >
          <h5 className="font-2 ms-2 mb-0">{t("lang.Filter")} </h5>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <div className="py-3 px-3">
            <div className="primaryI text-[18px] font-semibold">
              {filterTitle}
            </div>
            <div className="px-2 flex gap-2 mb-3 ">
              <div>
                <div>Start Date</div>
                <ReactDatePicker
                  className="border text-[12px] px-2 py-2"
                  selected={filterStartDate}
                  onChange={(date) => filterStartSetDate(date)}
                  maxDate={new Date()}
                  dateFormat={dateFormat}
                />
              </div>
              <div>
                <div>End Date</div>
                <ReactDatePicker
                  className="border text-[12px] px-2 py-2"
                  selected={filterEndDate}
                  onChange={(date) => {
                    console.log(date, "Checkng");
                    filterEndSetDate(date);
                  }}
                  maxDate={new Date()}
                  dateFormat={dateFormat}
                />
              </div>
            </div>
            <div className="px-3">
              <ButtonComp
                onClick={() => filterBtn()}
                loader={filterLoader}
                text={"Filter"}
                btnClassName={
                  "w-full py-[8px] bg-primaryI text-white text-center font-medium"
                }
              />
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
