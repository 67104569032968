import React, { useState, useEffect } from "react";
import { OrderColumns, OrderColumnsIV } from "../Data";
import { TableComp } from "../../../Common/TableComp";

const PaymentDetails = ({ orderReport, repId, paymentLoading }) => {
  // useEffect(() => {
  //   const realReport = orderReport.filter((item) => item?.userId === repId);
  //   setRepReport(realReport);
  // }, [repId]);
  console.log(orderReport, "orderReport");

  if (paymentLoading) {
    return (
      <>
        <Spinner size="sm" />;
      </>
    );
  }

  return (
    <div>
      {orderReport?.length === 0 ? (
        <div className="item_container flex flex-col gap-[.7rem] mb-[8rem] items-center justify-center h-full w-full text-center">
          <img
            src="/svgs/noSchedule.svg"
            alt=""
            className="w-[5rem] h-[5rem]"
          />
          <span className="block">No Data</span>
        </div>
      ) : (
        <TableComp
          Data={orderReport?.slice().reverse()}
          columnsComp={OrderColumnsIV([orderReport]?.reverse())}
          pagination
          sortId={0}
        />
      )}
    </div>
  );
};

export default PaymentDetails;
