import Cookie from "js-cookie";
import { useTranslation } from "react-i18next";

export const getToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("user:accesskey");
  }
};

export const setLanguage = (token) => {
  localStorage.setItem("language", token);
};
export const getLanguage = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("language");
  }
};

export const storage = {
  localStorage: {
    set: (key, value) => localStorage?.setItem(key, JSON.stringify(value)),
    get: (key) => JSON.parse(localStorage?.getItem(key) || '""'),
    remove: (key) => localStorage?.removeItem(key),
  },
  cookieStorage: {
    set: (key, value, options) => Cookie?.set(key, value, options),
    get: (key) => Cookie?.get(key) || "",
    remove: (key, options) => Cookie.remove(key, options),
  },
};

export const setToken = (token) => {
  localStorage.setItem("user:accesskey", token);
};
export const DeleteAuthTokenMaster = (name) => {
  localStorage.removeItem(name);
};

export const YearMonth = () => {
  const { t } = useTranslation();

  return [
    t("lang.Jan"),
    t("lang.Feb"),
    t("lang.Mar"),
    t("lang.Apr"),
    t("lang.May"),
    t("lang.June"),
    t("lang.July"),
    t("lang.Aug"),
    t("lang.Sept"),
    t("lang.Oct"),
    t("lang.Nov"),
    t("lang.Dec"),
  ];
};

export const Months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export function formatNumber(number) {
  const billion = 1000000000;
  const million = 1000000;
  const thousand = 1000;

  if (number >= billion) {
    return (number / billion).toFixed(1) + "b";
  } else if (number >= million) {
    return (number / million).toFixed(1) + "m";
  } else if (number >= thousand) {
    return (number / thousand).toFixed(1) + "k";
  } else {
    return number.toString();
  }
}

export function firstDateOfTheMonth() {
  // Get the current date
  const currentDate = new Date();

  // Subtract 10 days from the current date
  // const

  // Return the new date in international standard format
  return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
}

export const currentDate = new Date();

export const handleKeyPress = (event) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

export const SortOrder = (dataset = []) => {
  return dataset?.sort((a, b) => {
    let bDate = new Date(b.createdAt);
    let aDate = new Date(a.createdAt);

    return aDate - bDate;
  });
};

export const NumberTable = (item, name) => {
  // console.log(item,'result')
  const index = item?.findIndex((object) => {
    // console.log(object._id, name,'result')
    return object?.id === name;
  });

  // console.log(index,item,'result'); // 👉️ 1
  return index ? index + 1 : 1;
};
