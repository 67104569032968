import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { currentDate, firstDateOfTheMonth } from "../../../../utils/helper";
import {
  useGetPaymentReportQuery,
  useGetScheduleQuery,
} from "../../../../store/Customer/CustomerApi";
import moment from "moment";
import { useFieldRepsQuery } from "../../../../store/DashBoard/DashBoardApi";
import { Spinner } from "react-bootstrap";
import { formatMoney } from "../../../../utils/formatMoney";
const Payment = ({ id }) => {
  const {
    data: payment,
    isLoading: paymentLoading,
    isSuccess: paymentSuccess,
  } = useGetPaymentReportQuery(id);
  const { data: deactivate, isLoading, isSuccess } = useFieldRepsQuery();

  const [userPayment, setUserPayment] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    if (paymentSuccess) {
      setUserPayment(payment?.data?.reports);
    }
  }, [payment?.data]);

  const [deactivateRep, setDeactivateRep] = useState({});
  // console.log(userPayment, "payment");
  return (
    <section className="activities_tab">
      <div className="item_container flex flex-col items-start justify-center gap-[1.4rem]">
        <>
          {paymentLoading ? (
            <div className="item_container">
              <Spinner size="sm" />
            </div>
          ) : paymentSuccess && payment?.data?.reports.length > 0 ? (
            userPayment
              ?.slice()
              .reverse()
              ?.map((item) => (
                <div
                  className="item_card  w-full flex gap-[1.5rem] items-start justify-start"
                  key={item?.type}
                >
                  <div className="icon_section flex items-center w-[3rem] h-[3rem] p-[.7rem] justify-center bg-[#4F008A]/10 rounded-full">
                    <img
                      src={"/svgs/payment.svg"}
                      alt={`Payment svg`}
                      className="w-[2rem] h-[2rem]"
                    />
                  </div>
                  {/* Text-section */}
                  <div className="text_section description w-full">
                    <span className="time block mb-[.3rem] text-[.8rem] text-[400] text-[#757575]">
                      {moment(item.createdAt).format("h a - MMMM Do YYYY")}
                    </span>
                    <p className="description text-[.8rem] text-[#1c1c1c]">
                      {item?.amount
                        ? `${item?.paymentMethod} payment of ${formatMoney(
                            item?.amount,
                            true,
                            ""
                          )} ${
                            item?.balanceAmount !== null
                              ? `with balance of ${formatMoney(
                                  item?.balanceAmount
                                )}`
                              : ""
                          } made by ${item?.customer?.businessName} at ${
                            item?.customer?.address
                          } with status of ${item?.paymentStatus}`
                        : `${item?.paymentMethod} payment made by ${item?.customer?.businessName} at ${item?.customer?.address} with status of ${item?.paymentStatus}`}
                    </p>
                  </div>
                </div>
              ))
          ) : (
            <div className="item_container flex flex-col gap-[.7rem] mb-[8rem] items-center justify-center h-full w-full text-center">
              <img
                src="/svgs/noSchedule.svg"
                alt=""
                className="w-[5rem] h-[5rem]"
              />
              <span className="block">No Data</span>
            </div>
          )}
        </>
      </div>
    </section>
  );
};

export default Payment;
