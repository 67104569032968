import React, { useState } from "react";
import moment from "moment";
import ButtonComp from "../../../Common/ButtonComp";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import "react-datepicker/dist/react-datepicker.css";

const CustomerReports = ({ id, activity, payment, order, debt }) => {
  const initialReportState = [
    {
      name: "Activity Report",
      startDate: null,
      endDate: null,
      data: activity?.data?.reports ?? [],
    },
    {
      name: "Payment Report",
      startDate: null,
      endDate: null,
      data: payment?.data?.reports ?? [],
    },
    {
      name: "Order Report",
      startDate: null,
      endDate: null,
      data: order?.data?.orders ?? [],
    },
    {
      name: "Debt Report",
      startDate: null,
      endDate: null,
      data: debt?.data?.reports ?? [],
    },
  ];

  const [reports, setReports] = useState(initialReportState);
  const [error, setError] = useState(null);

  const handleDateChange = (index, dateType, date) => {
    const updatedReports = reports.map((report, idx) => {
      if (idx === index) {
        return { ...report, [dateType]: date };
      }
      return report;
    });
    setReports(updatedReports);
    setError(null); // Reset error when dates change
  };

  const filterDataByDate = (data, startDate, endDate) => {
    if (!startDate || !endDate) {
      return data;
    }

    const start = moment(startDate).startOf("day");
    const end = moment(endDate).endOf("day");

    return data.filter((item) => {
      const date = moment(item.createdAt); // Ensure the date field is correct
      return date.isBetween(start, end, null, "[]");
    });
  };

  const flattenObject = (obj) => {
    const flattened = {};
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        flattened[key] = JSON.stringify(obj[key]);
      } else {
        flattened[key] = obj[key];
      }
    }
    return flattened;
  };

  return (
    <div className="item_container pb-[6rem] flex items-start justify-center flex-col gap-[2rem]">
      {reports.map((item, index) => {
        const filteredData = filterDataByDate(
          item?.data ?? [],
          item?.startDate,
          item?.endDate
        ).map(flattenObject);

        return (
          <section
            key={index}
            className="flex md:items-center custom:items-start md:justify-between custom:justify-center md:flex-row custom:flex-col md:w-[89%] w-full md:gap-[4rem] custom:gap-[1rem]"
          >
            <div className="report_name w-[60%]">
              <span className="block font-semibold">{item.name}</span>
            </div>
            <div className="datepicker w-full">
              <div className="input_container md:px-[1rem] px-[.5rem] gap-[.5rem] flex items-center justify-center border-solid border-[1px] border-gray-200 rounded-[6px] h-[3rem]">
                <DatePicker
                  selected={item.startDate}
                  onChange={(date) =>
                    handleDateChange(index, "startDate", date)
                  }
                  placeholderText="01/06/202*"
                  dateFormat="dd/MM/yyyy"
                  className="w-full"
                  selectsStart
                  startDate={item.startDate}
                  endDate={item.endDate}
                />
                <DatePicker
                  selected={item.endDate}
                  onChange={(date) => handleDateChange(index, "endDate", date)}
                  placeholderText="01/06/202*"
                  dateFormat="dd/MM/yyyy"
                  className="w-full"
                  selectsEnd
                  startDate={item.startDate}
                  endDate={item.endDate}
                  minDate={item.startDate}
                />
                <div className="item_container">
                  <figure className="date-icon my-auto">
                    <img
                      src="/svgs/customerdate.svg"
                      alt=""
                      className="w-[3rem] h-[3rem]"
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="button_container w-full">
              <CSVLink
                data={filteredData}
                filename={`${item.name}.csv`}
                className="btn w-full"
              >
                <ButtonComp
                  text="Download"
                  containerStyle={"bg-[#3DA84A] w-full text-white"}
                  src={"/svgs/downloadReport.svg"}
                />
              </CSVLink>
            </div>
          </section>
        );
      })}
    </div>
  );
};

export default CustomerReports;
