import React from "react";
import AdminLayout from "../../Layout/AdminLayout";
import CustomerComp from "../../Components/modules/Customer";

export default function Customers() {
  return (
    <AdminLayout active={4}>
      <CustomerComp />
    </AdminLayout>
  );
}
