import React, { useEffect, useState } from "react";
import { useGetStockReportQuery } from "../../../../store/Order/OrderApi";
import { OrderColumnsIII } from "../Data";
import { TableComp } from "../../../Common/TableComp";
import { Spinner } from "react-bootstrap";

const StockReport = ({ repId, getStock, stocksLoading, stocksSuccess }) => {
  const [stocks, setStocks] = useState([]);
  useEffect(() => {
    if (stocksSuccess) {
      setStocks(getStock);
    }
  }, [getStock]);

  console.log(stocks, "STOCKS");

  if (stocksLoading) {
    return (
      <div className="item_container flex items-center justify-center">
        <Spinner size="md" />
      </div>
    );
  }

  return (
    <div>
      {stocks?.length === 0 ? (
        <div className="item_container flex flex-col gap-[.7rem] mb-[8rem] items-center justify-center h-full w-full text-center">
          <img
            src="/svgs/noSchedule.svg"
            alt=""
            className="w-[5rem] h-[5rem]"
          />
          <span className="block">No Data</span>
        </div>
      ) : (
        <TableComp
          Data={[...stocks]?.slice().reverse()}
          columnsComp={OrderColumnsIII([...stocks]?.reverse())}
          pagination
          sortId={0}
        />
      )}
    </div>
  );
};

export default StockReport;
