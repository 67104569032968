import React from "react";
import AdminLayout from "../../../Layout/AdminLayout";
import ButtonComp from "../../Ui/Button";

import { useParams, useNavigate } from "react-router-dom";
// import { useGetSingleFieldRepQuery } from "../../../store/DashBoard/DashBoardApi";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import Activities from "./RepDetails/Activities";
import {
  useDeactivateRepMutation,
  useGetSingleFieldRepQuery,
} from "../../../store/Customer/CustomerApi";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import Schedule from "./RepDetails/Schedule";
import Payment from "./RepDetails/Payment";
import Leads from "./RepDetails/Leads";
import Reports from "./RepDetails/Reports";
import Customers from "./RepDetails/Customers";
import DebtorsReport from "./RepDetails/DebtorsReport";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
const RefDetails = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const [rep, setRep] = useState([]);
  const {
    data: getSingleFieldRep,
    isLoading: repLoading,
    isSuccess: repSuccess,
  } = useGetSingleFieldRepQuery(id);

  const [deactivateRep, { isLoading, isSuccess, refetch }] =
    useDeactivateRepMutation();

  const [repStatus, setRepStatus] = useState(null);

  useEffect(() => {
    if (repSuccess) {
      setRep(getSingleFieldRep?.data?.user);
    }
  }, [getSingleFieldRep, repSuccess]);

  const [active, setActive] = useState("Activities");

  const tabs = [
    {
      name: "Activities",
      element: <Activities id={id} />,
      tooltip: "View recent activities",
    },
    {
      name: "Schedule",
      element: <Schedule id={id} />,
      tooltip: "View rep schedule",
    },
    {
      name: "Payments",
      element: <Payment id={id} />,
      tooltip: "View payment history",
    },
    {
      name: "Leads",
      element: <Leads id={id} />,
      tooltip: "View potential leads",
    },
    {
      name: "Customers",
      element: <Customers id={id} />,
      tooltip: "View Field Rep Customers",
    },
    {
      name: "Debtors",
      element: <DebtorsReport id={id} />,
      tooltip: "View generated reports",
    },
    {
      name: "Reports",
      element: <Reports id={id} />,
      tooltip: "View generated reports",
    },
  ];

  const [activate, setActivate] = useState({});

  const handleDeactivate = async () => {
    const response = await deactivateRep({
      userId: rep?.id,
      deactivated: true,
    });

    if (response?.error) {
      toast.error(response?.error?.data?.message);
    } else {
      toast.success("Account Deactivated");
      setRep((prevRep) => ({ ...prevRep, deactivated: true }));
      refetch();
    }
  };

  const handleActivate = async () => {
    const response = await deactivateRep({
      userId: rep?.id,
      deactivated: false,
    });

    if (response?.error) {
      toast.error(response?.error?.data?.message);
    } else {
      toast.success("Account Activated");
      setRep((prevRep) => ({ ...prevRep, deactivated: false }));
      refetch();
    }
  };

  return (
    <AdminLayout active={6}>
      <section className="w-full mt-[1rem]">
        <div className="flex-section flex items-center justify-between md:flex-row flex-col w-full gap-[1rem]">
          <div className="rep_name flex items-center justify-start gap-[1rem] w-full">
            <button className="icon" onClick={() => navigate(-1)}>
              <img src="/svgs/back.svg" alt="" />
            </button>
            <span className="font-[500] text-[#333333] text-[1.2rem]">
              {rep?.firstName} {rep?.lastName}
            </span>
          </div>
          <div className="button_section flex items-center md:justify-end justify-start gap-[1rem] w-full">
            <button className="w-[8rem] md:py-[.8rem] py-[.6rem] text-[.89rem] border-solid border-[1px] border-[#8354CF] rounded-full bg-white flex items-center justify-center gap-[.74rem]">
              <img src="/svgs/report.svg" alt="" />
              Report
            </button>

            <button
              // onClick={() =>
              //   navigate(`/schedule/showlocation/${row?.id}/${row?.userId}`)
              // }
              className="w-[10rem] md:py-[.8rem] py-[.6rem] text-[.89rem] border-none outline-none text-white rounded-full bg-[#3DA84A] flex items-center justify-center gap-[.74rem]"
            >
              Track Location
            </button>
          </div>
        </div>
        <div className="rep_identity_section mt-[2rem] md:border-solid border-none border-[1px] border-gray-200 w-full rounded-[10px] flex items-start md:p-[1.1rem] justify-center md:flex-row custom:!flex-col md:gap-[1rem] gap-[3rem]">
          <div className="item_container flex items-start w-full justify-start gap-[2rem]">
            <figure className="image_section mt-[.4rem]">
              <img
                src={rep?.url || "/svgs/noimage.jpg"}
                alt=""
                className="md:h-[10rem] h-[7rem] rounded-[13px]"
              />
            </figure>
            <div className="name_section flex flex-col gap-[1rem]">
              <span className="block font-bold text-[1.3rem]">
                {rep?.firstName} {rep?.lastName}
              </span>
              <small className="status text-[#757575] font-[500]">
                Field Rep -
                {rep?.deactivated === true ? (
                  <span className="text-red-600">In-Active</span>
                ) : (
                  <span className="text-[#044E54]">Active</span>
                )}
              </small>
              <small className="customer_count block font-[500] text-[#434141]">
                {`Customers (${rep?.totalCustomers || 0})`}
              </small>

              {rep?.deactivated === true ? (
                <button
                  disabled={isLoading}
                  onClick={handleActivate}
                  className={`block rounded-full w-[8rem] border-solid border-[1px] bg-white text-green-600 border-green-400 font-[500] text-[.8rem] py-[.2rem]`}
                >
                  {isLoading ? (
                    <div className="small_container flex items-center justify-center gap-[.5rem]">
                      <Spinner size="sm" animation="border" />
                      Activating...
                    </div>
                  ) : (
                    <div className="small_container flex items-center justify-center gap-[.5rem]">
                      Activate
                    </div>
                  )}
                </button>
              ) : (
                <button
                  disabled={isLoading}
                  onClick={handleDeactivate}
                  className={`block rounded-full w-[8rem] border-solid border-[1px] bg-white text-[#D63434] border-[#D63434]/10 font-[500] text-[.8rem] py-[.2rem]`}
                >
                  {isLoading ? (
                    <div className="small_container flex items-center justify-center gap-[.5rem]">
                      <Spinner size="sm" animation="border" />
                      Deactivating...
                    </div>
                  ) : (
                    <div className="small_container flex items-center justify-center gap-[.5rem]">
                      Deactivate
                    </div>
                  )}
                </button>
              )}
            </div>
          </div>

          <div className="item_container flex items-start w-full justify-start gap-[2rem]">
            <div className="location_section flex flex-col gap-[1rem]">
              <div className="location flex flex-col gap-[1.1rem]">
                <span className="location font-[500] text-[#555555] text-[1rem]">
                  Location
                </span>
                <span className="address text-[#000000] font-[500]">
                  {rep?.fullAddress || "-"}
                </span>
              </div>
              <div className="date_added flex flex-col gap-[1.1rem]">
                <span className="location font-[500] text-[#555555] text-[1rem]">
                  Date Added
                </span>
                <span className="address text-[#000000] font-[500]">
                  {moment(rep?.createdAt).format("Do MMMM, YYYY")}
                </span>
              </div>
            </div>
            <div className="last_section flex flex-col gap-[1rem]">
              <div className="number flex flex-col gap-[1.1rem]">
                <span className="number font-[500] text-[#555555] text-[1rem]">
                  Phone Number
                </span>
                <span className="address text-[#000000] font-[500]">
                  {rep?.phoneNumber || "-"}
                </span>
              </div>
              <div className="email flex flex-col gap-[1.1rem]">
                <span className="email font-[500] text-[#555555] text-[1rem]">
                  Email
                </span>
                <span className="address text-[#000000] font-[500]">
                  {rep?.email}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="tabs_section mt-[3rem]">
          <div className="nav_container !sticky !top-0 w-full z-[100000] bg-white after:block after:w-full after:h-[.01rem] after:bg-[#4F008A]/10 mb-[1.9rem]">
            <div className="nav_section flex items-center justify-start gap-[1.1rem] w-full overflow-x-scroll no-scrollbar">
              {tabs?.map((item) => (
                <div className="nav_pills" key={item.name}>
                  <button
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Hello world!"
                    onClick={() => setActive(item.name)}
                    className={`text-[.9rem] pb-[1rem] ${
                      active === item.name
                        ? "text-[#4F008A] font-[500] border-b-[1px] border-[#4F008A]"
                        : " text-[#555555]"
                    }`}
                  >
                    {item?.name}
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className="pages_section">
            {tabs.map((item) => {
              if (active === item.name) {
                return item.element;
              }
            })}
          </div>
        </div>
      </section>
    </AdminLayout>
  );
};

export default RefDetails;
