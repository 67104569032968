import React, { useEffect } from "react";
import { ImageComponent } from "./FallbackImage";
import Skeleton from "react-loading-skeleton";
import { RiMenu2Line } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { getLanguage, setLanguage, storage } from "../../utils/helper";
import { Nav, NavDropdown } from "react-bootstrap";
import {
  useNotificationListQuery,
  useReadNotificationIIMutation,
  useReadNotificationMutation,
} from "../../store/DashBoard/DashBoardApi";
import moment from "moment";

export default function Header({ data, setToggle }) {
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const {
    data: notificationMessages,
    isLoading,
    isSuccess,
    refetch,
  } = useNotificationListQuery();
  const [
    readNotification,
    { isLoading: readNotificationLoading, data: notificationRead },
  ] = useReadNotificationMutation();

  const [
    readNotificationII,
    { isLoading: readNotificationLoadingII, data: notificationReadII },
  ] = useReadNotificationIIMutation();

  console.log(
    notificationMessages?.data,
    notificationRead,
    "notificationMessages"
  );

  // console.log(storage?.localStorage.get('language'),'Langunage')

  useEffect(() => {
    i18n.changeLanguage(getLanguage());
  }, []);

  const handleReadNotification = async (id) => {
    const responses = await readNotificationII({ id: id });
    if (responses?.data) {
      refetch();
    }
  };
  return (
    <div className="flex px-2 justify-between items-center">
      <div className="cursor-pointer d-lg-none" onClick={() => setToggle(true)}>
        <RiMenu2Line size={30} />
      </div>

      <div className="py-3 flex items-center justify-end border-bottom w-full">
        {/* <button
          onClick={() => {
            changeLanguage("fr");
            setLanguage("fr");
          }}
        >
          Fr
        </button>
        <button
          onClick={() => {
            changeLanguage("en");
            setLanguage("en");
          }}
        >
          en
        </button> */}
        <div className="  bg-gray md:w-[4rem] md:h-[4rem] h-[3rem] w-[3rem] rounded-full flex items-center justify-center">
          <ImageComponent width={20} height={20} src="/icon/global.png" />
        </div>

        <Nav className="">
          <NavDropdown
            // id="nav-dropdown-dark-example"
            title={
              <div className="bg-gray md:w-[4rem] md:h-[4rem] h-[3rem] w-[3rem] rounded-full flex items-center justify-center">
                <ImageComponent
                  width={20}
                  height={20}
                  src="/icon/notification-bing.png"
                />
              </div>
            }
            // menuVariant="dark"
          >
            <div className="h-[350px] overflow-y-scroll">
              <div className=" w-[350px]   ">
                <div className="flex justify-between mb-[15px] px-[16px]">
                  <div className="font-medium text-[15px]">
                    Notification
                    <span className="ms-2 text-[#9EA0AA]">All</span>
                  </div>
                  <div className="font-medium text-[15px] flex items-center gap-[10px]">
                    Mark all as read
                    <img className="" src="/icon/success-tick.svg" />
                  </div>
                </div>
                {notificationMessages?.data?.notifications?.map((item, i) => (
                  <div
                    className={`mb-2 text-[14px] ${
                      item?.isRead && "bg-[#F5F4F8]"
                    }  py-[8px] px-[16px] cursor-pointer hover:bg-[#F5F4F8]`}
                    onClick={() => {
                      // alert('clicked!')
                      handleReadNotification(item?.id);
                    }}
                  >
                    <div className="mb-1"> {item?.content}</div>
                    <div className="text-[12px] font-medium text-[#A5ACB8]">
                      {moment(item?.createdAt).startOf("day").fromNow()}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </NavDropdown>
        </Nav>
        <div className="bg-gray md:w-[12rem] cursor-pointer md:h-[3.8rem] w-[3.5rem] h-[3.5rem] rounded-full flex items-center justify-center gap-[.8rem]">
          <div className="hidden md:block">
            <div className="blackI font-medium text-[14px]">
              {data?.firstName} {data?.lastName}
            </div>
            <div className="blackIII text-[12px] capitalize">
              {data?.userRole?.split("_").join(" ") || (
                <Skeleton baseColor="#4f008a4d" width={20} />
              )}
            </div>
          </div>
          <ImageComponent width={40} height={40} src="/icon/user.png" />
        </div>
      </div>
    </div>
  );
}
