import React, { useState, useEffect } from "react";
import { useGetScheduleQuery } from "../../../../store/Customer/CustomerApi";
import { currentDate, firstDateOfTheMonth } from "../../../../utils/helper";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import { TableComp } from "../../../Common/TableComp";
import { ScheduleColumns, ScheduleColumnsII } from "../../Schedule/Data";
import { FiEdit } from "react-icons/fi";
import DateFilter from "../../../Common/DateFilter";

const Schedule = ({ id }) => {
  const [startDate, setStartDate] = useState(firstDateOfTheMonth());
  const [endDate, setEndDate] = useState(currentDate);
  const [repSchedule, setRepSchedule] = useState([0]);

  const {
    data: getScheduleData,
    isLoading,
    isSuccess,
    isFetching: getScheduleReFreshing,
    refetch,
  } = useGetScheduleQuery(
    {
      startDate,
      endDate,
    },
    {
      skip: !startDate || !endDate,
      refetchOnMountOrArgChange: false,

      // skipToken: startDate||startDate
    }
  );

  const realData = getScheduleData?.data.filter(
    (item) => item.userId === Number(id)
  );

  const navigate = useNavigate();
  const [showCavans, setShowCavans] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const tableDropDown = [
    {
      name: (
        <div className="text-[#656468] text-[13px] flex gap-2 items-center">
          <FiEdit color="#4F008A" /> View Schedule
        </div>
      ),
      action: (row) => {
        navigate(`/schedule/${row?.id}/Schedule`);
        setShowCavans("Schedule");
      },
    },
    {
      name: (
        <div className="text-[#656468] text-[13px] flex gap-2 items-center">
          <img alt="" src="/icon/refresh.svg" />
          Track Field Rep
        </div>
      ),
      action: (row) => {
        navigate(`/schedule/showlocation/${row?.id}/${row?.userId}`);
      },
    },
    {
      name: (
        <div className="text-[#D63434] text-[13px] flex gap-2 items-center">
          <img alt="" src="/icon/red-trash.svg" />
          Delete Schedule
        </div>
      ),
      action: (row) => {
        console.log(row, "row");
        setShowModal(true);
        // setShowCavans(true);
      },
    },
  ];

  return (
    <div className="schedule">
      <DateFilter
        filterLoader={isLoading}
        filterStartDate={startDate}
        filterEndDate={endDate}
        filterStartSetDate={setStartDate}
        filterEndSetDate={setEndDate}
        dateFormat="MM/dd/yyyy"
      />
      {isLoading && realData?.length === 0 ? (
        <section className="text-center font-serif text-[1.35rem] font-[500] justify-center items-center">
          <Spinner size="md" />
        </section>
      ) : isSuccess && realData?.length === 0 ? (
        <div className="item_container flex flex-col gap-[.7rem] mb-[8rem] items-center justify-center h-full w-full text-center">
          <img
            src="/svgs/noSchedule.svg"
            alt=""
            className="w-[5rem] h-[5rem]"
          />
          <span className="block">No Schedule Yet</span>
        </div>
      ) : (
        <div className="item_container">
          <TableComp
            loader={isLoading}
            Data={realData?.slice().reverse()}
            columnsComp={ScheduleColumnsII(
              tableDropDown,
              realData?.data?.length > 0 && [...realData?.data]?.reverse()
            )}
            pagination
          />
        </div>
      )}
    </div>
  );
};

export default Schedule;
