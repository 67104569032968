import React from 'react'
import AdminLayout from '../../Layout/AdminLayout'
import TopAreasComp from '../../Components/modules/Schedule copy'

export default function TopAreas() {
  return (
    <AdminLayout active={5}>
      <TopAreasComp/>
    </AdminLayout>
  )
}
