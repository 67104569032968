import React from "react";

const ButtonComp = ({ text, containerStyle, onClick, src }) => {
  return (
    <button
      className={`rounded-full flex items-center justify-center gap-[.8rem] px-[1rem] py-[.6rem] ${containerStyle}`}
      onClick={onClick}
    >
      {src && <img src={src} alt="" />}
      {text}
    </button>
  );
};

export default ButtonComp;
