import React, { useState } from "react";
import styled from "styled-components";
import Header from "../Components/Common/Header";
import SideBar from "../Components/Common/SideBar";
import withAuth from "../Hoc/withAuth";
import { useSelector } from "react-redux";
import { selectCurrentUserData } from "../store/auth";

function AdminLayout({ children, active, noPadding }) {
  const [toggle, setToggle] = useState(false);
  const userDetails = useSelector(selectCurrentUserData);
  // const Check =useSelector(Check);
  // console.log(userDetails ,'userDetails')

  // console.log(user,'userProfile')
  return (
    <AdminLayoutStyled Toggle={toggle} className="d-flex px-lg-0 min-vh-100">
      <div className="border border-1 h-100 side">
        <SideBar active={active} setToggle={setToggle} />
      </div>
      <main className="w-100 main">
        <Header setToggle={setToggle} data={userDetails} />
        <div
          className={`${
            !noPadding && "p-2 p-lg-4"
          } animate__fadeIn animate__animated ${
            active === 1 ? "!bg-gray-50" : ""
          } `}
        >
          {children}
        </div>
      </main>
    </AdminLayoutStyled>
  );
}

const AdminLayoutStyled = styled.div`
position: relative;
.side {
  position: fixed;
  width: 260px;
  // overflow-y: auto;
  top: 0;
  bottom: 0;
  scrollbar-width: thin;
  z-index:90
  scrollbar-width: none;
}
.main {
  margin-left: 260px;
  overflow:hidden
}
.sideBar {
  transition: all 1s;
  scrollbar-width: none;
  height: 500px;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
}

@media only screen and (max-width: 800px) {
  .main {
    margin-left: 0px;
   
    
  }
  .side {
    background:white;
    visibility: ${(props) => (props?.Toggle ? "visible" : "hidden")}!important;
    transition: all 0.5s;
    width: ${(props) => (props?.Toggle ? "260px" : "0px")}!important;
    z-index: 200;
    display:${(props) => (props?.Toggle ? "visible" : "none")}!important;
  }
}
`;

export default withAuth(AdminLayout);
