import { BsThreeDotsVertical } from "react-icons/bs";
import DropDownComp from "../../Ui/DropDown";
import moment from "moment";
import { formatMoney } from "../../../utils/formatMoney";

export const OrderColumns = (tableDropDown = []) => {
  return [
    {
      name: "ID",
      selector: (row) => <span>{`#${row?.No}`}</span>,
      width: "100px",
      sortable: true,
    },

    {
      name: "Customer",
      selector: (row) => row?.Customer,
      sortable: true,
      width: "150px",
    },

    {
      name: "Date",
      selector: (row) => <span>{row?.OrderDate}</span>,
      sortable: true,
      width: "150px",
    },

    {
      name: "Payment Status",
      selector: (row) => row?.PaymentStatus,
      sortable: true,
      width: "180px",
    },

    {
      name: "Payment Method",
      selector: (row) => row?.PaymentMethod,
      selector: (row) => row?.PaymentMethod,
      sortable: true,
      width: "200px",
      width: "200px",

      // width:'70px'
    },
    // {
    //   name: "Product",
    //   selector: (row) => row?.Product,
    //   sortable: true,
    //   // width:'100px'
    // },
    // {
    //   name: "Prod Qty",
    //   selector: (row) => row?.ProdQty,
    //   sortable: true,
    //   width: "180px",
    // },
    {
      name: "Amount",
      selector: (row) => row?.Amount,
      sortable: true,
      width: "200px",
    },
  ];
};

export const OrderColumnsIV = (tableDropDown = []) => {
  return [
    {
      name: "ID",
      selector: (row) => <span>{`#${row?.id}`}</span>,
      width: "100px",
      sortable: true,
    },

    {
      name: "Customer",
      selector: (row) => row?.customer?.contactPersonName,
      sortable: true,
      width: "150px",
    },

    {
      name: "Company Name",
      selector: (row) => row?.customer?.businessName,
      sortable: true,
      width: "250px",
    },

    {
      name: "Date",
      selector: (row) => <span>{moment(row?.createdAt).format("ll")}</span>,
      sortable: true,
      width: "150px",
    },

    {
      name: "Payment Status",
      selector: (row) => row?.paymentStatus,
      sortable: true,
      width: "180px",
    },

    {
      name: "Payment Method",
      selector: (row) => row?.paymentMethod,
      sortable: true,
      width: "200px",

      // width:'70px'
    },
    // {
    //   name: "Product",
    //   selector: (row) => row?.Product,
    //   sortable: true,
    //   // width:'100px'
    // },
    // {
    //   name: "Prod Qty",
    //   selector: (row) => row?.ProdQty,
    //   sortable: true,
    //   width: "180px",
    // },
    {
      name: "Amount",
      selector: (row) => formatMoney(row?.amount) || "-",
      sortable: true,
      width: "200px",
    },
  ];
};

export const OrderColumnsII = (tableDropDown = []) => {
  return [
    {
      name: "ID",
      selector: (row) => <span>{`#${row?.id}`}</span>,
      width: "100px",
      sortable: true,
    },

    {
      name: "Company Name",
      selector: (row) => row?.customer?.businessName,
      sortable: true,
      width: "170px",
    },

    {
      name: "Date Created",
      selector: (row) => <span>{moment(row?.createdAt).format("ll")}</span>,
      sortable: true,
      width: "150px",
    },

    {
      name: "Due Date",
      selector: (row) => <span>{moment(row?.updatedAt).format("ll")}</span>,
      sortable: true,
      width: "150px",
    },

    {
      name: "Phone Number",
      selector: (row) => row?.customer?.businessPhoneNumber,
      sortable: true,
      width: "200px",
    },

    // {
    //   name: "Amount",
    //   selector: (row) => formatMoney(row?.amount) || "-",
    //   sortable: true,
    //   width: "180px",
    // },
    {
      name: "Payment Status",
      selector: (row) => row?.paymentStatus || "-",
      sortable: true,
      width: "180px",
    },
    {
      name: "Balance Amount",
      selector: (row) => (
        <div className="">
          {row?.paymentStatus === "Part payment" ? (
            <div>{formatMoney(row?.balanceAmount) || "-"}</div>
          ) : (
            <div>
              {formatMoney(row?.amount) ||
                formatMoney(row?.balanceAmount) ||
                "-"}
            </div>
          )}
        </div>
      ),
      sortable: true,
      width: "200px",
    },
  ];
};

export const OrderColumnsIII = (tableDropDown = []) => {
  return [
    {
      name: "ID",
      selector: (row) => <span>{`#${row?.orderId}`}</span>,
      width: "100px",
      sortable: true,
    },

    {
      name: "Product",
      selector: (row) => row?.product?.productName,
      sortable: true,
      width: "170px",
    },

    {
      name: "Quantity",
      selector: (row) => row?.quantity,
      sortable: true,
      width: "150px",
    },

    // {
    //   name: "Total Volume",
    //   selector: (row) => row?.totalVolumn || "-",
    //   sortable: true,
    //   width: "150px",
    // },
    // {
    //   name: "Price",
    //   selector: (row) => formatMoney(row?.price),
    //   sortable: true,
    //   width: "150px",
    // },

    {
      name: "Date Added",
      selector: (row) => <span>{moment(row?.createdAt).format("ll")}</span>,
      sortable: true,
      width: "150px",
    },
  ];
};

export const CategoryData = [
  {
    name: (
      <span className="text-[14px] flex py-[4px] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#4F008A] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        All
      </span>
    ),
  },
  {
    name: (
      <span className="text-[14px] flex text-[#656468] py-[4px] pr-[3rem] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#A67FC4] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        Pharmacy Channel
      </span>
    ),
  },
  {
    name: (
      <span className="text-[14px] flex text-[#656468] py-[4px] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#A67FC4] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        Wholesales Chan...
      </span>
    ),
  },
  {
    name: (
      <span className="text-[14px] flex text-[#656468] py-[4px] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#A67FC4] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        GPO Channels
      </span>
    ),
  },
];

export const OrderDetails = (tableDropDown) => {
  return [
    {
      name: "S/N",
      selector: (row) => row.id,
      width: "70px",
      width: "70px",
      // sortable: true,
    },

    {
      name: "Prod. Name",
      selector: (row) => (
        <div className="w-full">
          <div>{row?.product?.productName}</div>
          {/* <div className="border-[1px] border-[#E5DAEE] ps-[4px] py-1  bg-[#F6F2F9] text-[#444444] rounded-md text-[14px] w-[150px] capitalize">{row?.customer?.businessName}</div> */}
        </div>
      ),
      sortable: true,
      selector: (row) => (
        <div className="w-full">
          <div>{row?.product?.productName}</div>
          {/* <div className="border-[1px] border-[#E5DAEE] ps-[4px] py-1  bg-[#F6F2F9] text-[#444444] rounded-md text-[14px] w-[150px] capitalize">{row?.customer?.businessName}</div> */}
        </div>
      ),
      sortable: true,
      // width:'200px'
    },

    {
      name: "QTY",
      selector: (row) => (
        <div>
          {/* <div>{row.customer?.address},{row?.customer?.lga}</div> */}
          <div>{row?.quantity}</div>
        </div>
      ),
      sortable: true,
      // width:'200px'
      selector: (row) => (
        <div>
          {/* <div>{row.customer?.address},{row?.customer?.lga}</div> */}
          <div>{row?.quantity}</div>
        </div>
      ),
      sortable: true,
      // width:'200px'
    },
    {
      name: "Price",
      selector: (row) => formatMoney(row?.price),
      sortable: true,
      // width:'150px'
    },
  ];
};
