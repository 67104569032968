import React from 'react'
import ButtonComp from '../../Ui/Button'
import { t } from 'i18next';

const Privacy_Policy = () => {

    const items = [
        {
          header: t(`lang.PP2`),
          content:  t(`lang.PP3`),
        },
        {
          header:  t(`lang.PP4`),
          content:  t(`lang.PP5`),
        },
        {
            header: t(`lang.PP6`),
            content: t(`lang.PP5`),
          },

          {
            header: t(`lang.PP7`),
            content: t(`lang.PP3`),
          },

          {
            header:t(`lang.PP8`),
            content: t(`lang.PP5`),
          },

          {
            header: t(`lang.PP9`),
            content: t(`lang.PP3`),
          },

          {
            header: t(`lang.PP10`),
            content: t(`lang.PP5`),
          },

          {
            header: t(`lang.PP11`),
            content: t(`lang.PP5`),
          },


        
      ];
  return (
    <div>
        <div className='flex flex-col md:flex-row  justify-between items-center'> 

            <div className='text-center   md:text-start mt-8'>  
                <h1 className='text-lg md:text-start font-bold' >{t(`lang.PPS`)}</h1>
                <p className='text-sm'>{t(`lang.PP1`)}</p>
            </div>

            <div className='flex space-x-5'>
            <ButtonComp

            text={t(`lang.CCUS`)}
            btnClassName={"  px-4 border-2  border-[#BDA0D3] py-2 rounded-pill"}
            />
                
            </div>
            </div>

        <div className='md:grid grid-cols-2'> 
                {items.map((item, index) => (
                <div key={index} className="mb-4 p-2    ">
                <h2 className="text-lg  text-[#4F008A] font-medium">{item.header}</h2>
                <p className="mt-2 px-2 py-4 border border-gray-300 rounded-md">{item.content} <span className='text-[#4F008A]'> Continue Reading </span> </p>
                </div>
            ))}
        </div>
    </div>
  )
}

export default Privacy_Policy
