import React, { useEffect, useState } from "react";
import { useGetAllFieldRepActivitiesQuery } from "../../../../store/Customer/CustomerApi";
import { Spinner } from "react-bootstrap";
import { act } from "react";
import moment from "moment";
import { formatMoney } from "../../../../utils/formatMoney";

const ActivityReport = ({
  repId,
  getActivities,
  activitiesLoading,
  activitiesSuccess,
  isFetching,
}) => {
  const [activities, setActivities] = useState([]);

  console.log(isFetching, activitiesLoading, "Activities");

  // useEffect(() => {
  //   if (activitiesSuccess) {
  //     setActivities(getActivities);
  //   }
  // }, [getActivities]);

  if (activitiesLoading || isFetching) {
    return (
      <div className="item_container flex items-center justify-center">
        <Spinner size="md" />
      </div>
    );
  }

  return (
    <div className="">
      {getActivities?.length === 0 ? (
        <div className="item_container flex flex-col gap-[.7rem] mb-[8rem] items-center justify-center h-full w-full text-center">
          <img
            src="/svgs/noSchedule.svg"
            alt=""
            className="w-[5rem] h-[5rem]"
          />
          <span className="block">No Data</span>
        </div>
      ) : (
        <div className="item_container flex flex-col gap-[1.5rem]">
          {getActivities
            ?.slice()
            .reverse()
            .map((item) => {
              if (item?.reportType === "") {
                return null;
              }

              return (
                <div
                  className="item_card flex items-center justify-center gap-[1rem]"
                  key={item?.id}
                >
                  <div className="item_container">
                    <div className="image_container md:h-[3rem] md:w-[3rem] h-[2.7rem] !w-[2.7rem] rounded-full bg-[#4F008A]/10 flex items-center justify-center">
                      <img
                        src={`/svgs/${
                          item?.reportType === "payment"
                            ? "payment"
                            : item?.reportType === "debtor"
                            ? "debtors"
                            : item?.reportType === "userActivities"
                            ? "visit"
                            : "box"
                        }.svg`}
                        alt=""
                        className="md:h-[1.5rem] md:w-[1.5rem] h-[1rem] w-[1rem]"
                      />
                    </div>
                  </div>
                  <div className="text_container gap-[.3rem] w-full flex flex-col items-start justify-between h-full">
                    <small className="block text-[.7rem] text-gray-400">
                      {moment(item?.createdAt).format("h a - MMMM Do YYYY")}
                    </small>
                    <span className="block md:text-[.8rem] text-[.7rem]">
                      {item?.reportType === "debtor" ? (
                        <div className="item_container">
                          {item?.paymentStatus === "Part payment"
                            ? `Debt Payment of ${formatMoney(
                                item?.amount
                              )} by ${item?.customer?.contactPersonName} of ${
                                item.customer?.businessName
                              } with balance of ${formatMoney(
                                item?.balanceAmount
                              )} and status of ${item?.paymentStatus}`
                            : `Debt of ${formatMoney(item?.amount)} owed by ${
                                item?.customer?.contactPersonName
                              } of ${
                                item.customer?.businessName
                              } with status of ${item?.paymentStatus}`}
                        </div>
                      ) : item?.reportType === "payment" ? (
                        `${item?.paymentMethod} payment made by ${item?.customer?.businessName} at ${item?.customer?.address} with status of ${item?.paymentStatus}`
                      ) : item?.reportType === "userActivities" ? (
                        `${item?.reportMessage}`
                      ) : (
                        `Item delivery`
                      )}
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default ActivityReport;
