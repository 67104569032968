import React from 'react'
import ButtonComp from '../../Ui/Button'
import { t } from 'i18next'

const Password = () => {
  return (
    <div>
        <div className='flex flex-col md:flex-row  justify-between items-center'> 

            <div className='text-center md:text-start mt-8'>  
                <h1 className='text-lg md:text-start font-bold'> Personal Information</h1>
                <p className='md:text-start text-sm'> Update your password to secure your account </p>
            </div>

            <div className='flex space-x-5'>
            <ButtonComp
        
        text={t(`lang.D`)}
          btnClassName={"  px-5 border-2  border-[#DE5A5A] py-2 rounded-pill"}
        />
                <ButtonComp
        
        text={t(`lang.SC`)}
        btnClassName={"  bg-[#3DA84A] text-white px-5 py-2 rounded-pill"}
      />
            </div>
        </div>


        <section className="">
  <div className="flex flex-col items-center justify-center    md:justify-start md:w-[50%]">
      
      <div className="w-full mt-10    ">
          <div className=" space-y-4 md:space-y-6">
              
              <form className="space-y-4 md:space-y-6" action="#">
                  <div>
                      <label for="email" className="block mb-2 text-sm font-medium text-[#4F008A]">  {t(`lang.CC`)}</label>
                      <input type="password" name="password" id="password" placeholder="••••••••" className=" border border-gray-300 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 mt-4" required=""/>
                  </div>
                  <div>
                      <label for="password" className="block mb-2 text-sm font-medium text-[#4F008A]">   {t(`lang.NP`)}</label>
                      <input type="password" name="password" id="password" placeholder="••••••••" className=" border border-gray-300 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 mt-4" required=""/>
                  </div>
                  <div>
                      <label for="password" className="block mb-2 text-sm font-medium text-[#4F008A]">  {t(`lang.CNP`)}</label>
                      <input type="password" name="password" id="password" placeholder="••••••••" className=" border border-gray-300 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 mt-4" required=""/>
                  </div>
                  
                  
              </form>
          </div>
      </div>
  </div>
</section>
    </div>
  )
}

export default Password
