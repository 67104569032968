import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import { formatNumber } from '../../../utils/helper';



export  const SimpleChart = ({Data}) =>{


    return (
        <SimpleChartLayoutStyled>
        <div style={{ width: '100%', height: 200 }}>
        <ResponsiveContainer>
        <LineChart
          width={500}
          height={300}
          data={Data}
          margin={{
            top: 5,
            right: 5,
            left: -10,
            bottom: 5,
          }}
        >
          
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis strokeWidth={3}  axisLine={false} tickLine={false} dataKey="month" />
          <YAxis strokeWidth={3}  axisLine={false} tickLine={false}   tickFormatter={(data) => formatNumber(data)} />
          <Tooltip />
          {/* <Legend /> */}
          <Line strokeWidth={3}   type="monotone" dataKey="totalSalesOrders" stroke="#4F008A" activeDot={{ r: 1}} dot={false} />
          <Line strokeWidth={3}  type="monotone" dataKey="totalSalesCollection" stroke="#6979F8" activeDot={{ r: 1}} dot={false} />
        </LineChart>
      </ResponsiveContainer>
      </div>
      </SimpleChartLayoutStyled>
    );
  
}


const SimpleChartLayoutStyled = styled.span`
.recharts-text {
    font-size:12px !important;
}

`