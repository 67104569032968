import React, { useState, useEffect, useMemo } from "react";
import ListViewInCanvas from "../../../Common/ListViewInCanvas";
// import { ImageComponent } from '../../../Common/FallbackImage
import SelectComp from "../../../Ui/SelectComp";
import { statusStyleII } from "../../Lead/Data";
import {
  useAddFieldRepMutation,
  useGetCompanyQuery,
} from "../../../../store/Customer/CustomerApi";
import NaijaStates from "naija-state-local-government";
import { useSelector } from "react-redux";
import { selectCurrentUserData } from "../../../../store/auth";
import useFormValidator from "use-form-input-validator";
import ButtonComp from "../../../Ui/Button";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Country from "../../../../country.json";

export default function ViewCustomerDetailsComp({
  data = [],
  edit,
  onNext,
  setShowCavans,
  refetch,
}) {
  const [userData, setUserData] = useState(data);
  const [state, setState] = useState();
  const companyId = useSelector(selectCurrentUserData);

  const statesList = NaijaStates.all();
  const navigate = useNavigate();
  const { data: CompanyData, isLoading: LoadingCompany } = useGetCompanyQuery();
  const [
    addFieldRep,
    { isLoading, isSuccess, data: FieldData, isError, error },
  ] = useAddFieldRepMutation();
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
    handleSubmit && handleSubmit(userData);
  };

  const { values, errors, updateField, isAllFieldsValid } = useFormValidator({
    email: { checks: "required|email", value: "" },
    firstName: { checks: "required", value: "" },
    // userType: { checks: "required", value: "" },
    lastName: { checks: "required", value: "" },
    country: { checks: "required", value: "" },
    state: { checks: "required", value: "" },
    fullAddress: { checks: "required", value: "" },

    // lga: { checks: "required", value: "" },
    // expiringDate: { checks: "required", value: "" },
    // companyId: { checks: "required", value: "" },
    // lgas: { checks: "required", value: "" },
    // state: { checks: "required", value: "" },
  });

  useEffect(() => {
    if (isSuccess) {
      toast.success("User Created Successfully...");
      refetch();
      setShowCavans(false);
      // onNext()
      // navigate('/')
    }
  }, [error, isError, isSuccess, onNext]);

  const handleSubmit = async () => {
    const value = {
      email: values?.email,
      firstName: values?.firstName,
      lastName: values?.lastName,
      country: values?.country,
      userType: "supplier",
      userRole: "sales_rep",
      state: values?.state,
      companyId: companyId?.companyId,
      territories: regs,
      fullAddress: values?.fullAddress,
    };
    if (isAllFieldsValid()) {
      const response = await addFieldRep(value);
      // console.log(response, "value");

      if (response?.error) {
        toast.error(response?.error?.data?.message);
      }
    }
  };

  // const handleLga = (lgas) => {
  //   console.log(lgas, "LGAS");
  //   const newArray = [];
  //   if (lgas) {
  //     NaijaStates.lgas(lgas)?.lgas?.forEach((item) => {
  //       newArray.push({
  //         value: item,
  //         lga: item,
  //       });
  //     });
  //   }
  //   // console.log(lgas,newArray,'handleLga')
  //   return newArray;
  // };

  const [lgas, setLgas] = useState(null);

  // const handleStateChange = (e) => {
  //   const selectedState = e.target.value;
  //   updateField(e);
  //   setLgas(NaijaStates.lgas(selectedState)?.lgas || []);

  //   console.log(NaijaStates.lgas(selectedState)?.lgas);

  //   console.log(selectedState);
  // };

  // console.log(
  //   Country?.find((item) => item?.name === values?.country)?.state?.find(
  //     (item) => item?.name === values?.state
  //   )?.city,
  //   values?.state,
  //   "values"
  // );

  const [regs, setRegs] = useState([]);

  const handleRegion = (region) => {
    const diss = regs.find((item) => item === region);

    if (diss === region) {
      return null;
    }

    regs.push(region);
    setRegs([...regs]);
  };

  const removeRegion = (region) => {
    let news = regs.filter((reg, i) => i !== region);

    setRegs([...news]);
  };

  return (
    <>
      {
        <div>
          <div className="border-[rgba(79, 0, 138, 0.1)] border-[1px] py-[50px] pl-[20px]  xl:pl-[30px] pr-[20px] xl:pr[0px] flex-1 mb-[30px]">
            <div className="row gy-4">
              <div className="col-lg-6 ">
                <ListViewInCanvas
                  title={"Rep First Name"}
                  ItemName={values?.firstName}
                  edit={edit}
                  defaultValue={values?.firstName}
                  name="firstName"
                  error={errors.firstName}
                  onChange={updateField}
                />
              </div>
              <div className="col-lg-6 ">
                <ListViewInCanvas
                  title={"Rep Last Name"}
                  ItemName={values?.lastName}
                  edit={edit}
                  wrapperClassName="mb-3"
                  labelClassName={"primaryI"}
                  defaultValue={values?.lastName}
                  // label={t("lang.PN")}
                  name="lastName"
                  onChange={updateField}
                  error={errors.lastName}
                />
              </div>
              <div className="col-lg-12 ">
                <ListViewInCanvas
                  title={"Country"}
                  customItem={
                    <div className={statusStyleII(data?.status)}>
                      {userData?.country}
                    </div>
                  }
                  edit={edit}
                  customItemI={
                    <SelectComp
                      value={userData?.country}
                      inputName={"name"}
                      // label={t("lang.PN")}

                      labelClassName={"primaryI"}
                      wrapperClassName="mb-3"
                      name="country"
                      defaultValue={values?.country}
                      options={Country}
                      error={errors.status}
                      onChange={updateField}
                    />
                  }
                />
              </div>

              <div className="col-lg-6 ">
                <ListViewInCanvas
                  title={"State"}
                  customItem={
                    <div className={statusStyleII(data?.status)}>
                      {userData?.status}
                    </div>
                  }
                  edit={edit}
                  customItemI={
                    <SelectComp
                      value={values?.state}
                      inputName={"name"}
                      // label={t("lang.PN")}

                      labelClassName={"primaryI"}
                      wrapperClassName="mb-3"
                      name="state"
                      defaultValue={values?.state}
                      options={
                        values?.country
                          ? Country?.find(
                              (item) => item?.name === values?.country
                            )?.state
                          : []
                      }
                      error={errors.state}
                      onChange={updateField}
                    />
                  }
                />
              </div>
              <div className="col-lg-6 ">
                <ListViewInCanvas
                  title="Field Territory"
                  customItem={
                    <div className={statusStyleII(data?.status)}>
                      {userData?.status}
                    </div>
                  }
                  edit={edit}
                  customItemI={
                    <SelectComp
                      value={values?.lga}
                      options={
                        values?.state
                          ? Country?.find(
                              (item) => item?.name === values?.country
                            )?.state?.find(
                              (item) => item?.name === values?.state
                            )?.city
                          : []
                      }
                      name="territories"
                      onChange={(e) => handleRegion(e.target.value)}
                      inputName={"name"}
                      error={errors.lga}
                    />
                  }
                />

                <div
                  className={`local_goverment_results mt-[1rem] !overflow-y-scroll ${
                    regs.length >= 3 ? "h-[14rem]" : ""
                  } flex flex-col gap-[1rem] ${
                    regs.length > 0 ? "block" : "hidden"
                  }`}
                >
                  {regs?.map((item, i) => (
                    <div
                      className="item_card flex items-center justify-between px-[1rem] bg-gray-100 rounded-[10px] !h-[4.5rem]"
                      key={item}
                    >
                      {item}
                      <button onClick={() => removeRegion(i)}>
                        <img
                          src="/svgs/cancel.svg"
                          alt=""
                          className="w-[1.5rem] h-[1.5rem]"
                        />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-lg-12 mb-2">
                <ListViewInCanvas
                  title={"Full Address"}
                  ItemName={userData?.fullAddress}
                  edit={edit}
                  wrapperClassName="mb-3"
                  labelClassName={"primaryI"}
                  defaultValue={values?.fullAddress}
                  // label={t("lang.PN")}
                  name="fullAddress"
                  onChange={updateField}
                  error={errors.fullAddress}
                />
              </div>

              <div className="col-lg-12  mb-7">
                <ListViewInCanvas
                  title={"Email"}
                  ItemName={userData?.email}
                  edit={edit}
                  wrapperClassName="mb-3"
                  labelClassName={"primaryI"}
                  defaultValue={values?.email}
                  // label={t("lang.PN")}
                  name="email"
                  onChange={updateField}
                  error={errors.email}
                  placeholder="Enter Email Address"
                />
              </div>
              {/* <div className="w-full">
                <ListViewInCanvas
                  title={"Phone Number"}
                  ItemName={userData?.email}
                  edit={edit}
                  wrapperClassName="mb-3"
                  labelClassName={"primaryI"}
                  defaultValue={values?.email}
                  // label={t("lang.PN")}
                  name="number"
                  onChange={updateField}
                  error={errors.number}
                  placeholder="+2348000000000"
                />
              </div> */}

              {/* Not needed */}

              <div className="row  mb-[64px] gap-[15px] justify-center mt-[50px]">
                <div className="col-5 ">
                  <ButtonComp
                    text={"Discard"}
                    onClick={() => setShowCavans(false)}
                    btnClassName={
                      "text-[14px] lg:text-[16px] w-full  py-[.8rem] lg:py-[13px]  px-[20px] lg:px-[34px] border-[0.64px] rounded-full border-[#D63434] text-[#D63434]"
                    }
                  />
                </div>
                <div className="col-5">
                  <ButtonComp
                    loader={isLoading}
                    type="submit"
                    onClick={handleSubmit}
                    btnClassName={
                      "text-[14px] lg:text-[16px] w-full py-[.8rem] lg:py-[13px] px-[25px] lg:px-[47px] border-[0.64px] rounded-full border-[#3DA84A] text-[#fff] bg-[#4F008A]"
                    }
                    text={"Create Rep"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}
