import React, { useEffect, useState } from "react";
import { ImageComponent } from "../../Common/FallbackImage";
import ButtonComp from "../../Ui/Button";
import { useNavigate } from "react-router-dom";
import InputComp from "../../Ui/InputComp";
import SelectComp from "../../Ui/SelectComp";
import TextareaComp from "../../Ui/TextArea";
import ModalComp from "../../Ui/ModalComp";
import { AiFillPlusCircle } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import useFormValidator from "use-form-input-validator";
import {
  useAddProductMutation,
  useGetCategoriesQuery,
} from "../../../store/Product/ProductApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import AddProductCsv from "./submodules/AddProductCsv";
import AddDynamicPricing from "./submodules/AddSynamicPrincing";
import { uploadFileToS3 } from "./helper";
import { Spinner } from "react-bootstrap";
// const initialState = [
//   {
//     priceType: "wholesale",
//     amount: 0,
//     currency: "NGN",
//   },
//   {
//     priceType: "retail",
//     amount: 0,
//     currency: "NGN",
//   },
//   {
//     priceType: "hospital",
//     amount: 0,
//     currency: "NGN",
//   },
//   {
//     priceType: "PPMV",
//     amount: 0,
//     currency: "NGN",
//   },
// ];

export default function AddProductComp() {
  const { t } = useTranslation();

  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [showDynamicPricing, setShowDynamicPricing] = useState(false);
  const [productCsv, setProductCsv] = useState(false);
  const navigate = useNavigate();
  const [picture, setPicture] = useState(null);
  const [image, setImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result);
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }

    // console.log(reader.readAsDataURL(file));
  };

  const { data: getCategories } = useGetCategoriesQuery();

  const [
    addProduct,
    { isLoading: AddproductLoader, isSuccess: AddProductIsSuccess },
  ] = useAddProductMutation();

  const fileInputRef = React.useRef(null);
  const [prices, setPrices] = useState([]);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const { values, errors, updateField, isAllFieldsValid } = useFormValidator({
    productName: { checks: "required", value: "" },
    productSku: { checks: "required", value: "" },
    composition: { checks: "required", value: "" },
    categoryId: { checks: "required", value: "" },
    // expiringDate: { checks: "required", value: "" },
    shelfLife: { checks: "required|num", value: "" },
    nafdacNo: { checks: "required", value: "" },
    productDescription: { checks: "required", value: "" },
    status: { checks: "required", value: "" },
    productPhotoUrl: { checks: "required", value: "" },
  });

  const [inputPrice, setInputPrice] = useState({
    priceType: "wholesale",
    amount: 0,
    currency: "NGN",
  });
  const [inputPrice2, setInputPrice2] = useState({
    priceType: "retail",
    amount: 0,
    currency: "NGN",
  });
  const [inputPrice3, setInputPrice3] = useState({
    priceType: "hospital",
    amount: 0,
    currency: "NGN",
  });
  const [inputPrice4, setInputPrice4] = useState({
    priceType: "PPMV",
    amount: 0,
    currency: "NGN",
  });

  const currentUser = JSON.parse(localStorage.getItem("user_details_profile"));

  // console.log(currentUser?.user?.companyId, "CurrentUserCompanyId");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);

  const handleUpload = async () => {
    if (!file) {
      toast.error("No File Selected");
      setLoading(false);
    } else {
      try {
        setLoading(true);
        const bucketName = "suppliers-product-img";
        const folderName = currentUser?.user?.companyId; // Optional: Set a folder name in S3
        const fileUrl = await uploadFileToS3(file, bucketName, folderName);
        setLoading(false);

        if (fileUrl || !loading) {
          setUploadedFile(fileUrl);
        }

        toast.success("File Uploaded Successfully");
        console.log(process.env, "Needed FILE!!!");
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  // Handle file selection
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const takeWholesale = (e) => {
    setInputPrice({
      priceType: e.target.name,
      amount: e.target.value === "" ? 0 : parseInt(e.target.value),
      currency: "NGN",
    });
  };
  const takeRetail = (e) => {
    setInputPrice2({
      priceType: e.target.name,
      amount: e.target.value === "" ? 0 : parseInt(e.target.value),
      currency: "NGN",
    });
  };
  const takeHospital = (e) => {
    setInputPrice3({
      priceType: e.target.name,
      amount: e.target.value === "" ? 0 : parseInt(e.target.value),
      currency: "NGN",
    });
  };
  const takePPMV = (e) => {
    setInputPrice4({
      priceType: e.target.name,
      amount: e.target.value === "" ? 0 : parseInt(e.target.value),
      currency: "NGN",
    });
  };

  // const handleChange = (e, index) => {
  //   e.preventDefault();
  //   const updateArr = [...prices];
  //   updateArr[index] = { ...updateArr[index], [e.target.name]: e.target.value };
  //   setPrices(updateArr);
  // };

  const handleAddProduct = (e) => {
    e.preventDefault();
    let one = [inputPrice, inputPrice2, inputPrice3, inputPrice4];

    const value = {
      productName: values?.productName,
      productSku: values?.productSku,
      prices: one,
      categoryId: values?.categoryId,
      composition: values?.composition,
      shelfLife: values?.shelfLife,
      nafdacNo: values?.nafdacNo,
      productPhotoUrl: uploadedFile,
      status: values?.status,
      productDescription: values?.productDescription,
    };

    if (isAllFieldsValid()) {
      // console.log(value);
      addProduct(value)
        .unwrap()
        .then((data) => {
          // Toast
          console.log(data, "addProduct");
          toast.success(data?.message);
          // navigate("/product");
        })
        .catch((err) => {
          console.log(err);
          if (Array.isArray(err?.data?.message)) {
            toast.error(err?.data?.message[0]);
          } else {
            toast.error(err?.data?.message);
          }
        });
    }
  };

  useEffect(() => {
    if (AddProductIsSuccess) {
      setShowAddProductModal(true);
    }
  }, [AddProductIsSuccess]);

  const handleSubmitDynamic = () => {
    toast.success("Add successfully...");
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-5">
        <h4
          className="flex gap-2 items-center font-semibold"
          onClick={() => navigate("/product")}
        >
          <ImageComponent src="/icon/back.svg" className="cursor-pointer" />
          Add Product
        </h4>
        <ButtonComp
          onClick={() => setProductCsv(true)}
          btnClassName={"px-4 py-[10px] rounded-pill bg-primaryI text-white"}
          text={
            <span className="flex gap-2 ">
              {" "}
              <ImageComponent
                src="/icon/upload.png"
                width={24}
                height={24}
                className="cursor-pointer"
              />
              Product CSV File
            </span>
          }
        />
      </div>
      {/*  */}
      <div className="flex flex-wrap lg:flex-nowrap justify-between mb-5">
        <div className="w-full lg:w-[45%]">
          <div className="pr-[20px] col-12">
            <InputComp
              wrapperClassName="mb-3"
              labelClassName={"primaryI"}
              defaultValue={values?.productName}
              label={t("lang.PN")}
              placeholder={"Enter Product Name"}
              name="productName"
              onChange={updateField}
              error={errors.productName}
            />
            <InputComp
              wrapperClassName="mb-3"
              labelClassName={"primaryI"}
              label={t("lang.PS")}
              defaultValue={values?.productSku}
              placeholder={"Enter Product SKU"}
              name="productSku"
              onChange={updateField}
              error={errors.productSku}
            />
            <InputComp
              wrapperClassName="mb-3"
              labelClassName={"primaryI"}
              label={"Composition"}
              name="composition"
              defaultValue={values?.composition}
              onChange={updateField}
              error={errors.composition}
              placeholder={"Enter Product Composition"}
            />
            <SelectComp
              label={"Category"}
              labelClassName={"primaryI"}
              wrapperClassName="mb-3"
              name="categoryId"
              onChange={updateField}
              error={errors.categoryId}
              options={getCategories?.data?.categories}
              defaultValue={values?.categoryId}
            />
            <SelectComp
              label={t("lang.AS")}
              labelClassName={"primaryI"}
              wrapperClassName="mb-3"
              name="status"
              onChange={updateField}
              defaultValue={values?.status}
              error={errors?.status}
              options={[
                { name: "Available", id: "Available" },
                // {name:'Discontinued',id:'discontinued '},
                { name: "Out of stock", id: "Out of stock" },
              ]}
            />
            <InputComp
              wrapperClassName="mb-3"
              labelClassName={"primaryI"}
              label={t("lang.NAFDAC")}
              name="nafdacNo"
              // type="number"
              onChange={updateField}
              error={errors.nafdacNo}
              defaultValue={values?.nafdacNo}
            />
          </div>
        </div>
        <div className="col-lg-1 flex text-center justify-center">
          <div className="border-r-2 h-full border-[#4F008A]"></div>
        </div>
        <div className="w-full lg:w-[45%]">
          <div className="">
            <div className="">
              <div className="item-container w-full flex items-center max-[1000px]:!flex-col flex-row gap-[1rem]">
                <InputComp
                  wrapperClassName="mb-3 w-full"
                  labelClassName={"primaryI"}
                  label="Product Price (Wholesaler)"
                  placeholder={`4000`}
                  name="wholesale"
                  type="number"
                  defaultValue={values?.productPriceWholesalers || "-"}
                  onChange={(e) => takeWholesale(e)}
                  error={errors.productPrice}
                />

                <InputComp
                  wrapperClassName="mb-3 w-full"
                  labelClassName={"primaryI"}
                  label="Product Price (Retailer)"
                  placeholder={`4000`}
                  name="retail"
                  type="number"
                  defaultValue={values?.productPriceRetailer || "-"}
                  onChange={(e) => takeRetail(e)}
                  error={errors.productPrice}
                />
              </div>

              <div className="item-container w-full flex items-center flex-col md:flex-row justify-between gap-[1rem]">
                <InputComp
                  wrapperClassName="mb-3 w-full"
                  labelClassName={"primaryI"}
                  label="Product Price(Hospital)"
                  placeholder={`4000`}
                  name="hospital"
                  type="number"
                  defaultValue={values?.productPricePriceHospital || "-"}
                  onChange={(e) => takeHospital(e)}
                  error={errors.productPrice}
                />

                <InputComp
                  wrapperClassName="mb-3 w-full"
                  labelClassName={"primaryI"}
                  label="Product Price(PPMV)"
                  placeholder={`4000`}
                  name="PPMV"
                  type="number"
                  defaultValue={values?.productPricePPMV || "-"}
                  onChange={(e) => takePPMV(e)}
                  error={errors.productPrice}
                />
              </div>

              <InputComp
                wrapperClassName="mb-3"
                labelClassName={"primaryI"}
                label="Expiry Date"
                type="number"
                name="shelfLife"
                onChange={updateField}
                error={errors?.shelfLife}
                defaultValue={values?.shelfLife}
                prefixIcon={
                  <span>
                    <img src="/svgs/date.svg" alt="date icon" />
                  </span>
                }
                placeholder={`In Years `}
              />
            </div>

            <TextareaComp
              wrapperClassName="mb-4"
              name="productDescription"
              onChange={updateField}
              error={errors?.productDescription}
              labelClassName={"primaryI"}
              label={t("lang.PD")}
              defaultValue={values?.productDescription}
              // type="date"
              // prefixIcon={<span>date</span>}
              // placeholder={'Enter Product Price'}
            />
            <div>
              <div className={`mb-[10px] font-medium primaryI`}>
                {"Product Image"}
              </div>
              <div className="flex gap-[10px] items-center">
                <ImageComponent
                  src={image || "/Images/noImage.png"}
                  className="w-[64px] h-[64px]"
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept="image/*"
                  name="productPhotoUrl"
                  // onChange={updateField}
                  error={errors.productImage}
                  onChange={(e) => {
                    setPicture(e.target.files);
                    //convert Image to be display
                    handleImageChange(e);
                    handleFileChange(e);

                    updateField(e);
                  }}
                />
                <ButtonComp
                  onClick={!file ? handleButtonClick : handleUpload}
                  text={file ? "Upload Picture" : "Add Photo"}
                  btnClassName={
                    "px-[32px] py-[10px] bg-[#F6F2F9] text-[#282728] font-medium"
                  }
                  loader={loading}
                />

                {/* <button
                  onClick={handleUpload}
                  className="bg-slate-700 flex items-center justify-center gap-[.5rem] rounded-[10px] text-white w-[8rem] h-[2.5rem]"
                >
                  {loading && <Spinner size="sm" />}
                  Upload
                </button> */}
                <div className="">(Format Supported: *.jpeg and *.png)</div>
              </div>
              {errors.productImageCheck && (
                <div className="text-danger text-start ps-1">
                  {"Picture"} {errors?.productImageCheck}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mb-5 flex items-center justify-center w-full">
        <ButtonComp
          loader={AddproductLoader}
          onClick={(e) => handleAddProduct(e)}
          text={"Add Product"}
          btnClassName={
            "bg-[#3DA84A] disabled:bg-gray-300 disabled:text-gray-500 text-white px-5 py-[10px] rounded-pill"
          }
          disabled={!uploadedFile}
        />
      </div>
      <ModalComp
        show={showAddProductModal}
        bodyComponents={<AddProductModal navigate={navigate} />}
        handleClose={() => setShowAddProductModal(false)}
      />
      <ModalComp
        show={productCsv}
        size="lg"
        handleClose={() => setProductCsv(false)}
        bodyComponents={<AddProductCsv setProductCsv={setProductCsv} />}
      />
      <ModalComp
        show={showDynamicPricing}
        handleClose={() => setShowDynamicPricing(false)}
        size={"lg"}
        bodyComponents={
          <AddDynamicPricing setShowDynamicPricing={setShowDynamicPricing} />
        }
      />
    </div>
  );
}

const AddProductModal = ({ navigate }) => {
  return (
    <>
      <div className="flex justify-center mb-[40px]">
        <ImageComponent src="/icon/success-product.svg" />
      </div>
      <div className="text-center mb-[30px] lg:mb-[62px]">
        <h5 className="font-semibold text-[18px] lg:text-[22px] mb-[16px] mx-[30px] lg:mx-[72px]">
          Product Added Successfully!
        </h5>
        <div className="text-[16px]">
          Your product has been successfully added and you can easily share the
          details with field reps
        </div>
      </div>
      <div className="flex justify-center  mb-[64px] gap-[15px]">
        <ButtonComp
          onClick={() => navigate("/")}
          text={"Go to dashboard"}
          btnClassName={
            "text-[14px] lg:text-[16px]  py-[5px] lg:py-[13px]  px-[20px] lg:px-[44px] border-[0.64px] rounded-full border-[#3DA84A] text-[#3DA84A]"
          }
        />
        <ButtonComp
          onClick={() => navigate("/product")}
          btnClassName={
            "text-[14px] lg:text-[16px] py-[5px] lg:py-[13px] px-[25px] lg:px-[57px] border-[0.64px] rounded-full border-[#3DA84A] text-[#fff] bg-[#3DA84A]"
          }
          text={"View Product"}
        />
      </div>
    </>
  );
};
