import React, { useEffect, useState } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from "react-google-maps";
import Autocomplete from "react-google-autocomplete";
import {
  useGetLocationsQuery,
  useGetRepLocationQuery,
} from "../../../store/Customer/CustomerApi";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const ShowMapComp = () => {
  const { customerId, id } = useParams();
  const google = window.google;

  const {
    data: DataMap,
    isLoading,
    isSuccess,
  } = useGetRepLocationQuery(
    { id: customerId },
    {
      skip: !customerId,
      refetchOnMount: true,
      refetchInterval: 3000,
      // Refresh interval in milliseconds
    }
  );

  console.log(customerId, "id");

  console.log(DataMap?.data, "Data");

  const [mapState, setMapState] = useState({
    center: {
      lat: Number(DataMap?.data[0]?.latitude),
      lng: Number(DataMap?.data[0]?.longitude),
    },
    zoom: 13,
  });

  useEffect(() => {
    setMapState({
      center: {
        lat: Number(DataMap?.data[0]?.latitude),
        lng: Number(DataMap?.data[0]?.longitude),
      },
      zoom: 13,
    });
  }, [isSuccess]);

  const onPlaceSelected = (place) => {
    setMapState({
      center: place.geometry.location,
      zoom: 18,
    });
  };

  const Auto = (props) => (
    <Autocomplete
      style={{ width: "90%" }}
      onPlaceSelected={(place) => {
        // console.log(place);
        onPlaceSelected(place);
      }}
      types={["(regions)"]}
    />
  );

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-[100vh]">
        <Spinner size="md" />
      </div>
    );
  }

  if (isSuccess && DataMap?.data?.length === 0) {
    return (
      <div className="flex items-center justify-center h-[100vh]">
        <div>No items on map to display</div>
      </div>
    );
  }

  const GoogleMapExample = withGoogleMap((props) => (
    <GoogleMap center={mapState.center} zoom={mapState.zoom}>
      {/* {DataMap?.data?.map((item, i) => (
        <Marker
          position={{
            lat: Number(item?.latitude),
            lng: Number(item?.longitude),
          }}
        />
      ))} */}
      {
        <Marker
          position={{
            lat: Number(DataMap?.data[0]?.latitude),
            lng: Number(DataMap?.data[0]?.longitude),
          }}
        />
      }
    </GoogleMap>
  ));

  return (
    <div>
      <div style={{}}>
        {/* <h3>Choose another destination</h3> */}
        <Auto />
      </div>
      <GoogleMapExample
        containerElement={<div style={{ height: `90vh`, width: "100%" }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
};

export default ShowMapComp;
