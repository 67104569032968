import React from "react";

const RightSidebar = ({ setToggle, setOpenModal }) => {
  const handleCreateRep = () => {
    setToggle(false);
    setOpenModal(true);
  };
  return (
    <div className="fixed top-0 right-0 w-full h-[100vh] bg-black/50 flex items-start justify-end">
      <div className="md:w-[50%] w-full bg-white overflow-y-scroll no-scrollbar md:px-[2rem] px-[1rem] py-[3.5rem] h-full">
        <div className="flex items-end justify-between mb-[3rem]">
          <button onClick={() => setToggle(false)}>
            <img src="/svgs/cancel.svg" alt="" />
          </button>

          <span className="block font-bold text-[#444444] text-[1.4rem]">
            Add New Field Rep
          </span>
        </div>

        <div className="form_section border-solid border-[1px] border-gray-300 md:px-[2rem] px-[1rem] py-[3rem] font-[500] rounded-[7px] min-h-[85vh] w-full">
          <div className="input_container flex md:flex-row flex-col items-center justify-between gap-[1rem] w-full">
            <div className="input_element md:w-[50%] w-full h-full">
              <label
                htmlFor="Firstname"
                className="mb-[.5rem] text-[#4F008A] text-[.9rem] font-[500] block "
              >
                Rep First Name
              </label>
              <input
                type="text"
                placeholder="Enter First Name"
                className="outline-none border-solid border-[1px] h-[2.7rem] border-[#4F008A]/10 px-[.9rem] placeholder:text-[.9rem] text-[16px] placeholder:text-[#757575] rounded-[8px] w-full"
                required
              />
            </div>

            <div className="input_element md:w-[50%] w-full h-full">
              <label
                htmlFor="Lastname"
                className="mb-[.5rem] text-[#4F008A] text-[.9rem] font-[500] block "
              >
                Rep Last Name
              </label>
              <input
                type="text"
                placeholder="Enter Last Name"
                className="outline-none border-solid border-[1px] h-[2.7rem] border-[#4F008A]/10 px-[.9rem] placeholder:text-[.9rem] text-[16px] placeholder:text-[#757575] rounded-[8px] w-full"
                required
              />
            </div>
          </div>

          <div className="input_container w-full mt-[2rem]">
            <div className="input_element w-full h-full">
              <label
                htmlFor="email"
                className="mb-[.5rem] text-[#4F008A] text-[.9rem] font-[500] block "
              >
                Email Address
              </label>
              <input
                type="email"
                placeholder="Enter Email Address"
                className="outline-none border-solid border-[1px] h-[2.7rem] border-[#4F008A]/10 px-[.9rem] placeholder:text-[.9rem] text-[16px] placeholder:text-[#757575] rounded-[8px] w-full"
                required
              />
            </div>
          </div>

          <div className="input_container w-full mt-[2rem]">
            <div className="input_element w-full h-full">
              <label
                htmlFor="number"
                className="mb-[.5rem] text-[#4F008A] text-[.9rem] font-[500] block "
              >
                Phone Number
              </label>
              <input
                type="number"
                placeholder="+2348000000000"
                className="outline-none input border-solid border-[1px] h-[2.7rem] border-[#4F008A]/10 px-[.9rem] placeholder:text-[.9rem] text-[16px] placeholder:text-[#757575] rounded-[8px] w-full"
                required
              />
            </div>
          </div>

          <div className="input_container mt-[2rem] flex md:flex-row flex-col items-center justify-between gap-[1rem] w-full">
            <div className="input_element md:w-[50%] w-full h-full">
              <label
                htmlFor="country"
                className="mb-[.5rem] text-[#4F008A] text-[.9rem] font-[500] block "
              >
                Country
              </label>

              <select
                name="Country"
                id="country"
                placeholder="Enter Country"
                className="outline-none border-solid border-[1px] h-[2.7rem] border-[#4F008A]/10 px-[.9rem] placeholder:text-[.9rem] text-[16px] placeholder:text-[#757575] rounded-[8px] w-full"
                defaultValue={""}
              >
                <option value="" disabled>
                  Enter Country
                </option>
                <option value="ogun">Nigeria</option>
              </select>
            </div>

            <div className="input_element md:w-[50%] w-full h-full">
              <label
                htmlFor="state"
                className="mb-[.5rem] text-[#4F008A] text-[.9rem] font-[500] block "
              >
                State
              </label>
              <select
                name="State"
                id="state"
                placeholder="Lagos State"
                className="outline-none border-solid border-[1px] h-[2.7rem] border-[#4F008A]/10 px-[.9rem] placeholder:text-[.9rem] text-[16px] placeholder:text-[#757575] rounded-[8px] w-full"
                defaultValue={""}
              >
                <option value="" disabled>
                  Lagos State
                </option>
                <option value="ogun">Ogun State</option>
              </select>
            </div>
          </div>

          <div className="input_container mt-[2rem] flex items-center justify-between gap-[1rem] w-full">
            <div className="input_element w-[50%] h-full">
              <label
                htmlFor="Firstname"
                className="mb-[.5rem] text-[#4F008A] text-[.9rem] font-[500] block "
              >
                Territory
              </label>

              <select
                name="territories"
                id=""
                placeholder="LGA"
                className="outline-none border-solid border-[1px] h-[2.7rem] border-[#4F008A]/10 px-[.9rem] placeholder:text-[.9rem] text-[16px] placeholder:text-[#757575] rounded-[8px] w-full"
                defaultValue={""}
              >
                <option value="" disabled>
                  LGA
                </option>
                <option value="Ipogun">Ipogun</option>
              </select>
            </div>

            <button className="input_element w-[50%] h-[2.7rem] flex items-center justify-start gap-[.5rem] mt-[1.8rem]">
              <img src="/svgs/add.svg" alt="" />
              <span className="block font-[500] text-[.9rem] text-[#4F008A]">
                Add Territory
              </span>
            </button>
          </div>

          <div className="input_container w-full mt-[2rem]">
            <div className="input_element w-full h-full">
              <label
                htmlFor="fullAddress"
                className="mb-[.5rem] text-[#4F008A] text-[.9rem] font-[500] block "
              >
                Full Address
              </label>
              <input
                type="text"
                placeholder="Enter Full Address"
                className="outline-none border-solid border-[1px] h-[2.7rem] border-[#4F008A]/10 px-[.9rem] placeholder:text-[.9rem] text-[16px] placeholder:text-[#757575] rounded-[8px] w-full"
                required
              />
            </div>
          </div>

          <div className="input_container w-full mt-[2rem]">
            <div className="input_element w-[50%] h-full">
              <label
                htmlFor="number"
                className="mb-[.5rem] text-[#4F008A] text-[.9rem] font-[500] block "
              >
                Status
              </label>
              <select
                name="status"
                id=""
                defaultValue={"active"}
                className="outline-none border-solid border-[1px] border-[#044E54] w-[9rem] rounded-[5px] bg-[#044E54]/10 py-[.7rem] px-[.3rem]"
              >
                <option value="active">Active</option>
                <option value="inActive">In-Active</option>
                <option value="deActived">Deactived</option>
              </select>
            </div>
          </div>
        </div>
        <div className="button_flex_components mt-[4rem] flex items-center justify-center gap-[1rem]">
          <button
            onClick={() => setToggle(false)}
            className="w-[9rem] outline-none border-solid border-[1px] border-[#D63434] h-[2.3rem] text-[.9rem] rounded-full"
          >
            Discard
          </button>
          <button
            onClick={handleCreateRep}
            className="w-[9rem] outline-none border-none bg-[#4F008A] text-white h-[2.3rem] text-[.9rem] rounded-full"
          >
            Create Rep
          </button>
        </div>
      </div>
    </div>
  );
};

export default RightSidebar;
