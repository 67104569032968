import React from 'react'
import AdminLayout from '../../Layout/AdminLayout'
import OrderManagementComp from '../../Components/modules/Order_Management'

export default function OrderManagement() {
  return (
    <AdminLayout active={3}>
      <OrderManagementComp/>
    </AdminLayout>
  )
}
