import React from "react";
import AdminLayout from "../../Layout/AdminLayout";
import AddProductComp from "../../Components/modules/Product/addproduct";

export default function AddProduct() {
  return (
    <AdminLayout active={2}>
      <AddProductComp />
    </AdminLayout>
  );
}
