import React from 'react'
import AdminLayout from '../../Layout/AdminLayout'
import ShowSchedularComp from '../../Components/modules/Schedule/showSchedular'

export default function ViewScheduler() {
  return (
    <AdminLayout active={2}>
    <ShowSchedularComp/>
  </AdminLayout>
  )
}
