import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

export default function SwiperComp({ Data,swiperRef,onSlideChange }) {
  return (
    <div>
      <Swiper ref={swiperRef} pagination={true} modules={[Pagination]} onSlideChange={onSlideChange} className="mySwiper">
        {Data?.map((item, i) => (
          <SwiperSlide>
            <div>
              <img src={item?.icon} className="w-full mb-4" />
              <h4 className="fw-bold mb-4">{item?.name}</h4>
              <p className="mb-16">{item?.desc}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
