import { fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { getAuthToken, getToken, storage } from "../utils/helper";
import { BASE_URL } from "../api/baseUrl";

// Create our baseQuery instance
const generatePrepareHeaders = (contentType) => {
  return (headers, { getState, extra }) => {
    const token = storage["localStorage"].get("user_details_profile")?.token;

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
      headers.set("Content-Type", "application/json");
    }

    // if (contentType) {
    //   headers.set("Content-Type", contentType);
    // }

    return headers;
  };
};

export const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: generatePrepareHeaders(null),

  // (headers, { getState }, extra) => {
  //   // By default, if we have a token in the store, let's use that for authenticated requests
  //   const token = storage["localStorage"].get("user_details_profile")?.token;

  //   if (token) {
  //     headers.set("Authorization", `Bearer ${token}`);
  //     headers.set("Content-Type", "application/json");
  //     // headers.set("Content-Type", "image/png");
  //   }

  //   return headers;
  // },
});

export const baseQueryWithRetry = retry(baseQuery, { maxRetries: 6 });
